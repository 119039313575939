export const unparse_url = (parsed_url) => {
    const scheme = parsed_url['scheme'] ? parsed_url['scheme'] + '://' : '';
    const host = parsed_url['host'] ? parsed_url['host'] : '';
    const port = parsed_url['port'] ? ':' + parsed_url['port'] : '';
    const user = parsed_url['user'] ? parsed_url['user'] : '';
    let pass = parsed_url['pass'] ? ':' + parsed_url['pass'] : '';
    pass = (user || pass) ? pass + '@' : '';
    const path = parsed_url['path'] ? parsed_url['path'] : '';
    const query = parsed_url['query'] ? '?' + parsed_url['query'] : '';
    const hash = parsed_url['fragment'] ? '#' + parsed_url['fragment'] : '';
    return scheme + user + pass + host + port + path + query + hash;
}