import {Select} from "@mantine/core";
import Translation from "../../Utils/Translation";
import React, {useEffect} from "react";
import {FilterPropsType} from "../List";
import {useDebouncedState} from "@mantine/hooks";

export type SelectFilterPropsType = FilterPropsType&{
    values?: (string[])|Record<string, string>,
    translate?: boolean,
}

export default (props: SelectFilterPropsType) => {
    let ids = props.values ?? props.config?.fields?.[props.field]?.values;
    if (typeof ids === "object") {
        ids = Object.values(ids);
    }
    const fieldSettings = props.config?.fields?.[props.field];
    return (props.translate ?? fieldSettings?.translate) ? <Translation id={ids} base={[props.config.componentName + ".field." + props.field, props.config.componentName]}>
        <SelectComp {...props} />
    </Translation> : <SelectComp {...props} />;
}

const SelectComp = ({state, field, config, translations, setState, ...props}: SelectFilterPropsType&{translations?: {[key: string]: string}}) => {
    const fieldSettings = config?.fields?.[field];
    const [search, setSearch] = useDebouncedState(state.headerFilters?.[field]?.value ?? '', 300);
    useEffect(() => {
        const newHeaderFilters = {...(state.headerFilters || {})};
        if (search !== undefined && search !== null && search !== "") {
            newHeaderFilters[field] = {
                field,
                value: search
            }
            setState({...state, headerFilters: newHeaderFilters});
        } else if (newHeaderFilters[field]) {
            delete newHeaderFilters[field];
            setState({...state, headerFilters: newHeaderFilters});
        }
    }, [search]);
    let values;

    if (props.values && typeof props.values === "object") {
        values = Object.entries(props.values).map(([value, label]) => ({
            label: props.translate ?? fieldSettings?.translate ? translations[label] : label,
            value
        }));
    } else {
        values = (props.values ?? fieldSettings.values).map((value) => ({
            label: props.translate ?? fieldSettings?.translate ? translations[value] : value,
            value
        }));
    }
    return <Select
        name={field}
        label={false}
        value={search}
        clearable={true}
        data={values}
        onChange={(value) => setSearch(value)}
        radius="md"
    />
}