/**
 * https://github.com/dondevi/promise-abortable
 * Abortable Promise
 *
 * @author dondevi
 * @create 2019-05-27
 */

import getAbortController, {AbortControllerType, AbortSignalType} from "./controller";

// @ts-ignore
export default class AbortablePromise<T extends any = any> extends Promise<T> {
    protected abortController: AbortControllerType;

    public isPending: () => boolean;
    public isFulfilled: () => boolean;
    public isRejected: () => boolean;

    constructor (executor: <TResult1 = T, TResult2 = never>(onFulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null, onRejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null, signal?: AbortSignalType) => void, abortController?: AbortControllerType) {
        let _abortController = abortController || getAbortController();
        let _isPending = true;
        let _isFulfilled = false;
        let _isRejected = false;
        super((resolve, reject) => {
            executor((value) => {
                _isPending = false;
                _isFulfilled = true;
                resolve(value);
            }, (value) => {
                _isPending = false;
                _isRejected = true;
                reject(value);
            }, _abortController.signal);
        });
        this.abortController = _abortController;

        this.isPending = function () {
            return _isPending;
        }.bind(this);

        this.isFulfilled = function () {
            return _isFulfilled;
        }.bind(this);

        this.isRejected = function () {
            return _isRejected;
        }.bind(this);

        this.then = this.then.bind(this);
        this.abort = this.abort.bind(this);
    }

    // @ts-ignore
    then<TResult1 = T, TResult2 = never>(onfulfilled?: ((value: T) => (PromiseLike<TResult1> | TResult1)) | undefined | null, onrejected?: ((reason: any) => (PromiseLike<TResult2> | TResult2)) | undefined | null): Promise<TResult1 | TResult2> {
        return new AbortablePromise((resolve, reject, signal) => {
            const onSettled = (status: string, value: any, callback: any) => {
                if (this.abortController.signal.aborted) {
                    return;
                }
                if ("function" === typeof callback) {
                    value = callback(value);
                    if (value instanceof AbortablePromise) {
                        // @ts-ignore
                        Object.assign(signal, value.abortController.signal);
                    }
                    // @ts-ignore
                    return resolve(value);
                }
                // @ts-ignore
                status === "resolved" && resolve(value);
                // @ts-ignore
                status === "rejected" && reject(value);
            }
            super.then(
                value => onSettled("resolved", value, onfulfilled),
                reason => onSettled("rejected", reason, onrejected)
            );
        }, this.abortController);
    }

    // Equivalent to this.then(undefined, onRejected)
    // catch (onRejected) {}

    abort (reason?: any) {
        if (this.isPending()) {
            this.abortController.abort(reason);
        }
    }

    static all (promises: AbortablePromise<any>[]) {
        return new AbortablePromise((resolve, reject, signal) => {
            // @ts-ignore
            setPromisesAbort(promises, signal);
            Promise.all(promises).then(resolve, reject);
        });
    }

    static race (promises: AbortablePromise<any>[]) {
        return new AbortablePromise((resolve, reject, signal) => {
            // @ts-ignore
            setPromisesAbort(promises, signal);
            Promise.race(promises).then(resolve, reject);
        });
    }

};

/**
 * Set promises abort
 * @param {Array} promises - list of promise
 * @param {Object} signal - abort signal
 */
function setPromisesAbort (promises: any, signal: AbortSignalType) {
    signal.onAbort = (reason?: any) => {
        promises.forEach((promise: any) => {
            if (promise instanceof AbortablePromise) {
                promise.abort(reason)
            }
        });
    }
}