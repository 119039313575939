import React from "react";
import Translation from "../../../Utils/Translation";
import {Select} from "@mantine/core";
import {FieldPropsType} from "../../Editor";
import useReadOnly from "../useReadOnly";

export const view = null;
export default (props: FieldPropsType) => {
    const ids = props.config?.fields?.[props.field]?.values;
    return <Translation id={ids} base={[props.config.componentName + ".field." + props.field, props.config.componentName]}>
        <SelectComp {...props} />
    </Translation>;
}

const SelectComp = (props: {translations?: {[key: string]: string}, options?: any[]}&FieldPropsType) => {
    const form = props.form;
    const field = props.field;
    const values = (props.options ?? props.config?.fields?.[props.field]?.values).map((value) => ({label: props.translations[value], value}));
    const fieldReadOnly = useReadOnly(props);
    return <Select
        name={field}
        label={<Translation base={[props.config.componentName + ".field." + props.field, props.config.componentName]} id={field} />}
        withAsterisk={props.config?.fields?.[field].required}
        value={form.values?.[field] ?? ''}
        data={values}
        onChange={(value) => form.setFieldValue(field, value)}
        error={form.errors?.[field]}
        readOnly={fieldReadOnly || props.readOnly}
        radius="md"
    />
}