import {Select} from "@mantine/core";
import Translation from "../../Utils/Translation";
import React, {useEffect} from "react";
import {FilterPropsType} from "../List";
import {useDebouncedState} from "@mantine/hooks";

export default (props: FilterPropsType) => {
    const ids = Object.keys(props.config?.fields?.[props.field]?.states ?? {});
    return <Translation id={ids} base={[props.config.componentName + ".field." + props.field, props.config.componentName]}>
        <SelectComp {...props} />
    </Translation>;
}

const SelectComp = ({state, field, config, translations, setState}: FilterPropsType&{translations?: {[key: string]: string}}) => {
    const fieldSettings = config?.fields?.[field];
    const [search, setSearch] = useDebouncedState(state.headerFilters?.[field]?.value ?? '', 300);
    useEffect(() => {
        const newHeaderFilters = {...(state.headerFilters || {})};
        if (search !== undefined && search !== null && search !== "") {
            newHeaderFilters[field] = {
                field,
                value: search
            }
            setState({...state, headerFilters: newHeaderFilters});
        } else if (newHeaderFilters[field]) {
            delete newHeaderFilters[field];
            setState({...state, headerFilters: newHeaderFilters});
        }
    }, [search]);

    const values = Object.keys(fieldSettings?.states ?? {}).map((value) => ({label: translations[value], value}));
    return <Select
        name={field}
        label={false}
        value={search}
        clearable={true}
        data={values}
        onChange={(value) => setSearch(value)}
        radius="md"
    />
}