// based on: https://github.com/AdonisLau/axios-jsonp
let cid = 1;

function buildParams(params) {
    let result = [];

    for (let i in params) {
        result.push(encodeURIComponent(i) + '=' + encodeURIComponent(params[i]));
    }

    return result.join('&');
}

module.exports = function jsonpAdapter(config) {
    return new Promise(function(resolve, reject) {
        let script = document.createElement('script');
        let src = config.url;

        if (config.params) {
            let params = (config.paramsSerializer || buildParams)(config.params);

            if (params) {
                src += (src.indexOf('?') >= 0 ? '&' : '?') + params;
            }
        }

        script.async = true;

        function remove() {
            if (script) {
                script.onload = script.onreadystatechange = script.onerror = null;

                if (script.parentNode) {
                    script.parentNode.removeChild(script);
                }

                script = null;
            }
        }

        let jsonp = 'axiosJsonpCallback' + cid++;
        let old = window[jsonp];
        let isAbort = false;

        window[jsonp] = function(responseData) {
            window[jsonp] = old;

            if (isAbort) {
                return;
            }

            let response = {
                data: new Blob([JSON.stringify(responseData)], {type: "application/json"}),
                status: 200,
                statusText: "OK",
                headers: {
                    "content-type": "application/json"
                },
                config
            }

            resolve(response);
        };

        let additionalParams = {
            _: (new Date().getTime())
        };

        additionalParams[config.callbackParamName || 'callback'] = jsonp;

        src += (src.indexOf('?') >= 0 ? '&' : '?') + (config.paramsSerializer || buildParams)(additionalParams);

        script.onload = script.onreadystatechange = function() {
            if (!script.readyState || /loaded|complete/.test(script.readyState)) {
                remove();
            }
        };

        script.onerror = function() {
            remove();
            let e = new Error('Network Error');
            e.response = {
                data: new Blob([""], {type: "plain/text"})
            };
            reject(e);
        };

        if (config.cancelToken) {
            config.cancelToken.promise.then(function(cancel) {
                if (!script) {
                    return;
                }

                isAbort = true;

                reject(cancel);
            });
        }

        script.src = src;

        document.head.appendChild(script);
    });
};