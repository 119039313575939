import React, {useCallback, useState} from "react";
import {Select as CoreSelect} from "@mantine/core";
import Translation from "../../../Utils/Translation";
import {getRequest} from "../../../../utils/request/request";
import ComponentConfig from "../../../Utils/ComponentConfig";
import getSearchFields from "../../../../utils/component/getSearchFields";
import getLabelField from "../../../../utils/component/getLabelField";
import {minimumLengthOfSearch} from "../../../../config";
import Select, {SelectPropsType} from "./Select";
import list from "../../../../utils/array/list";
import {uniqueByField} from "../../../../utils/array/unique";
import {snakeCase} from "../../../../utils/string/change-case";
import useReadOnly from "../useReadOnly";

export const view = null;

const salt = "salt_" + (new Date()).getTime() + "_";

const SelectComp = (props: ComponentSelectPropsType&{
    labelField?: string|((item: any) => string),
    fieldComponentConfig: any,
}) => {
    const [components, setComponents] = useState([]);
    const form = props.form;
    const field = props.field;
    const labelField = getLabelField({config: props.fieldComponentConfig}) ?? props.labelField;
    const fieldSettings = props.config?.fields?.[field];
    let idField = props.fieldComponentConfig.idfield;
    const fieldReadOnly = useReadOnly(props);
    if (fieldSettings.combine) {
        idField = JSON.stringify(fieldSettings.combine).replace(/.*\"([a-zA-Z_0-9]+)\"\s*:\s*\"\{\{value\}\}\".*/gm, '$1');
    }
    const isList = fieldSettings.connection?.type == "hasMany";

    let targetChanged = fieldSettings.ref === false && !!props.fieldComponentConfig.targetConfig;
    //console.log(fieldSettings, props.fieldComponentConfig.targetConfig);
    if (targetChanged) {
        idField = props.fieldComponentConfig.fields[props.fieldComponentConfig.targetField].is ?? props.fieldComponentConfig.targetConfig.idfield;
    }

    let value = list(props.value ?? form.values?.[field]).map((item) => {
        return item && typeof item === "object" ? item[idField] : item;
    }).filter(Boolean);
    //console.log(value);
    if (!isList) {
        value = value[0] ?? null;
    }

    const options = useCallback(({searchValue}) => {
        if (fieldReadOnly) {
            return list(props.value ?? form.values?.[field]).map((item, idx) => {
                return {
                    label: (labelField instanceof Function ? labelField(item) : item[labelField]) ?? '',
                    value: item[idField] ?? (salt + "_" + idx),
                    data: item,
                }
            });
        }
        let values = list(value);
        let req;
        let timeout;
        let valueReq;
        let valueTimeout;
        let combineFilter = fieldSettings.combine ? Object.entries(fieldSettings.combine).reduce((filter, [field, value]) => {
            if (value === "{{value}}") return filter;
            return [
                ...filter,
                {field, value}
            ]
        }, []) : [];
        if (targetChanged) {
            combineFilter = [];
        }
        const res: Promise<any>&{abort?: () => void} = Promise.all([
            new Promise<{items: any[]}>((resolve) => {
                let datatype = props.config.fields[props.field].datatype;
                let searchFields = getSearchFields({config: props.fieldComponentConfig}) ?? [];
                if (targetChanged) {
                    datatype = props.fieldComponentConfig.targetConfig.componentName;
                    searchFields = getSearchFields({config: props.fieldComponentConfig.targetConfig}) ?? [];
                }
                timeout = setTimeout(() => {
                    req = getRequest("/components", {
                        option: datatype,
                        page: 1,
                        itemsperpage: 50,
                        filter: [
                            combineFilter.length ? combineFilter : undefined,
                            searchFields.length && searchValue.length >= minimumLengthOfSearch ? {
                                filters: searchFields.reduce((all, field) => {
                                    return [...all, ...searchValue.split(/\s+/).map((search) => ({
                                        field: field,
                                        value: `%${search}%`,
                                        operator: "LIKE"
                                    }))];
                                }, []),
                                operator: "OR"
                            } : undefined,
                            props.fieldComponentConfig.targetCombine ? Object.entries(props.fieldComponentConfig.targetCombine).reduce((filter, [field, value]) => {
                                return [
                                    ...filter,
                                    {field, value}
                                ]
                            }, []) : undefined,
                            !targetChanged && values.length ? {field: idField, value: values, operator: "not in"} : undefined,
                            props.filter,
                        ].filter(Boolean),
                    });
                    req.then((res) => {
                        res.items = res.items.map((item) => {
                            if (targetChanged) {
                                item[props.fieldComponentConfig.targetField] = item[idField];
                                if (props.fieldComponentConfig.targetConfig.idfield === props.fieldComponentConfig.idfield) {
                                    delete item[props.fieldComponentConfig.idfield];
                                }
                            }
                            return item;
                        });
                        resolve(res);
                    });
                }, 300);
            }),
            new Promise<{items: any[]}>((resolve) => {
                let listFieldFilter;
                if (isList) {
                    const foreignKey = fieldSettings.connection.foreignKey ?? (snakeCase(props.config.componentName) + '_id');
                    const localKey = fieldSettings.connection.localKey ?? props.config.idfield;
                    if (props.form.values[localKey]) {
                        listFieldFilter = {
                            field: foreignKey,
                            value: props.form.values[localKey]
                        };
                    } else {
                        listFieldFilter = {
                            field: foreignKey,
                            value: -1
                        };
                    }
                }
                if (values.length) {
                    valueTimeout = setTimeout(() => {
                        valueReq = getRequest("/components", {
                            option: props.config.fields[props.field].datatype,
                            page: 1,
                            itemsperpage: 20,
                            filter: [
                                listFieldFilter,
                                combineFilter.length ? combineFilter : undefined,
                                !targetChanged ? {
                                    field: idField,
                                    value: values,
                                    operator: "in"
                                } : undefined
                            ].filter(Boolean),
                        });
                        valueReq.then((res) => {
                            resolve(res);
                        });
                    }, 300);
                } else {
                    resolve({items: []});
                }
            })
        ]).then(([options, value]: {items: any[]}[]) => {
            const items = [...value.items, ...options.items].filter(uniqueByField(idField)).map((item, idx) => {
                return {
                    label: (labelField instanceof Function ? labelField(item) : item[labelField]) ?? '',
                    value: item[idField] ?? (salt + "_" + idx),
                    data: item,
                }
            });
            setComponents(items);
            return items;
        });
        res.abort = () => {
            clearTimeout(timeout);
            req?.abort();
            clearTimeout(valueTimeout);
            valueReq?.abort();
        }
        return res;
    }, [JSON.stringify(value), props.filter, fieldReadOnly]);

    const selectorFunction = useCallback(({newValue}) => {
        newValue = list(newValue);
        //console.log(newValue, idField);
        //console.log(components);
        newValue = components.filter((item) => {
            return newValue.includes(String(item.value));
        }).map((item) => {
            return item.data;
        });
        //console.log(newValue);
        if (isList) {
            return newValue;
        }
        return newValue[0] ?? null;
    }, [components]);

    return <Select
        {...props}
        value={value}
        options={options}
        selectorFunction={selectorFunction}
    />
}

export type ComponentSelectPropsType = SelectPropsType&{
    labelField?: string|((item: any) => string),
    filter?: any,
}

export default (props: ComponentSelectPropsType) => {
    const field = props.field;
    if (!props.config?.fields?.[props.field]?.datatype) return null;
    return <ComponentConfig
        component={props.config.fields[props.field].datatype}
        as="fieldComponentConfig"
        loader={
            <CoreSelect
                name={props.field}
                label={<Translation base={[props.config.componentName + ".field." + props.field, props.config.componentName]} id={props.field} />}
                withAsterisk={props.config?.fields?.[field].required}
                disabled={true}
                error={props.form.errors?.[props.field] && 'Invalid'}
                radius="md"
                data={[]}
            />
        }
    >
        <SelectComp
            {...props}
            fieldComponentConfig={null}
        />
    </ComponentConfig>
}