import React, {useState} from "react";
import {defaultPageSizes} from "./List";
import classNames from "classnames";
import {Button, Group, Input, Modal, NumberInput, Select} from "@mantine/core";
import Confirm from "../Utils/Confirm";

import {
    IconArrowBigRightFilled,
    IconArrowBigLeftFilled,
    IconRefresh
} from '@tabler/icons-react';

const ifCompletedInput = (event) => {
    if ([27].indexOf(event.which) !== -1) {
        event.preventDefault();
        return false;
    }
    if ([13, 9, 38, 40].indexOf(event.which) !== -1) {
        event.preventDefault();
        return true;
    }
    return false;
}

const ChangePage = (props) => {
    const [page, setPage] = useState(props.state.navigation.currpage);

    const changePage = (page) => {
        const pagecount = props.state.navigation.pagecount;
        if (!page) return;
        const pageNum = (props.state.itemsperpage === 0 ? 0 : Math.min(pagecount, Math.max(1, page)));
        setPage(pageNum);
    }

    if (props.state.navigation.pagecount <= 1) return null;

    return <Confirm
        title="Oldalszám"
        body={
            <NumberInput
                label={false}
                type="number"
                defaultValue={page}
                onChange={changePage}
            />
        }
        onAccept={() => {
            props.setState({...props.state, page});
        }}
    >
        {/*<span className="p-paginator-current">{props.pagecount}</span>*/}
        <Button variant="default">...</Button>
    </Confirm>;
}

export default class Pagination extends React.Component<any, {pageSize?: number,changePageSize?: boolean, currentPageSize?: number}> {

    constructor(props) {
        super(props);
        this.state = {
            pageSize: this.props.state.itemsperpage,
            changePageSize: false,
            currentPageSize: this.props.state.itemsperpage,
        }
        this.getPageCount = this.getPageCount.bind(this);
        this.changePageNumber = this.changePageNumber.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<{}>, snapshot?: any) {
        if (this.props.state.itemsperpage !== prevProps.state.itemsperpage) {
            this.setState({
                pageSize: this.props.state.itemsperpage
            })
        }
        if (this.props.state.itemsperpage !== prevProps.state.itemsperpage) {
            this.setState({
                currentPageSize: this.props.state.itemsperpage
            })
        }
    }

    getPageCount() {
        return this.props.state.navigation.pagecount;
    }

    changePageNumber(value) {
        if (!isNaN(value)) {
            this.props.setState({...this.props.state, itemsperpage: value, page: 1});
            this.setState({
                changePageSize: false
            });
        } else {
            this.setState({changePageSize: true});
        }
    }

    changeCurrentPageSize = (page) => {
        if (!page) return;
        const pageNum = Math.max(1, page);
        this.setState({currentPageSize: pageNum});
    }

    render() {
        const pageSize = this.props.state.itemsperpage;
        const pagecount = this.getPageCount();
        const currpage = Math.min(this.props.state.navigation.currpage, pagecount);
        let pageSizes: (number|string)[] = this.props.pageSizes || defaultPageSizes;
        if (!pageSizes.find((v) => v === pageSize)) {
            pageSizes = [pageSize, ...pageSizes];
        }
        pageSizes = [...pageSizes, "..."].sort((a,b) => {
            if (!Number.isNaN(a) && !Number.isNaN(b)) {
                return Number(a) - Number(b);
            } else if (!Number.isNaN(a)) {
                return -1;
            } else {
                return 1;
            }
        });
        return (
            <>
                {pageSize > 0 ? <>
                    <Modal
                        title="Darabszám"
                        opened={!!this.state.changePageSize}
                        onClose={() => {
                            this.setState({
                                changePageSize: false,
                                currentPageSize: this.props.state.itemsperpage
                            });
                        }}
                    >
                        <NumberInput
                            label={false}
                            defaultValue={this.state.currentPageSize}
                            onChange={this.changeCurrentPageSize}
                        />
                        <Group mt="xl" position="right">
                            <Button
                                variant="default"
                                onClick={async () => {
                                    this.setState({
                                        changePageSize: false,
                                        currentPageSize: this.props.state.itemsperpage
                                    });
                                }}
                            >
                                Mégse
                            </Button>
                            <Button
                                onClick={async () => {
                                    this.changePageNumber(this.state.currentPageSize);
                                }}
                            >
                                Mehet
                            </Button>
                        </Group>
                    </Modal>
                </> : null}
                <Group position="right">
                    <Button.Group>
                        {pageSize > 0 ? <>
                            <Button
                                variant="default"
                                disabled={currpage <= 1}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.setState({...this.props.state, page: currpage - 1})
                                }}
                            >
                                <IconArrowBigLeftFilled />
                            </Button>
                            {currpage > 4 && <>
                                <Button
                                    variant={currpage <= 1 ? "filled" : "default"}
                                    disabled={currpage <= 1}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.setState({...this.props.state, page: 1})
                                    }}
                                >
                                    1
                                </Button>
                                {currpage > 5 && <ChangePage {...this.props} />}
                            </>}
                            {[-3,-2,-1,0,+1,+2,+3].map((page, idx) => {
                                page = currpage + page;
                                if (page > 0 && page <= pagecount) {
                                    return <Button
                                        key={idx}
                                        variant={page === currpage ? "filled" : "default"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.props.setState({...this.props.state, page})
                                        }}
                                    >
                                        {page}
                                    </Button>
                                }
                            })}
                            {currpage + 3 < pagecount && <>
                                {currpage + 4 !== pagecount && <ChangePage {...this.props} pagecount={pagecount} currpage={currpage} />}
                                <Button
                                    variant={currpage == pagecount ? "filled" : "default"}
                                    disabled={currpage == pagecount}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.setState({...this.props.state, page: pagecount})
                                    }}
                                >
                                    {pagecount}
                                </Button>
                            </>}
                            <Button
                                variant="default"
                                disabled={currpage == pagecount}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.setState({...this.props.state, page: currpage + 1})
                                }}
                            >
                                <IconArrowBigRightFilled />
                            </Button>
                            <Select
                                value={String(pageSize)}
                                data={pageSizes.map((pageSize) => ({label: String(pageSize), value: String(pageSize)}))}
                                onChange={this.changePageNumber}
                            />
                        </> : null}
                        <Button
                            variant="default"
                            onClick={() => this.props.setState(this.props.state)}
                            disabled={this.props.isLoading}
                        >
                            <IconRefresh />
                        </Button>
                    </Button.Group>
                </Group>
            </>
        );
    }
}