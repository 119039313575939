import React, {useEffect, useState} from "react";
import {connect} from "../../valtio";
import { useForm } from '@mantine/form';
import {
    TextInput,
    PasswordInput,
    Paper,
    Group,
    PaperProps,
    Button,
    Stack,
    Loader, LoadingOverlay, Container, Text
} from '@mantine/core';
import {getRequest, postRequest} from "../../utils/request/request";
import Translation from "../Utils/Translation";
import {useWindowEvent} from "@mantine/hooks";
import debounce from "../../utils/debounce";

function AuthenticationForm(props: PaperProps&{state: any, message?: any}) {
    const [loading, setLoading] = useState(false);
    const form = useForm({
        initialValues: {
            email: '',
            password: '',
        },

        validate: {
            //email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
            //password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
        },
    });

    return (
        <Container size={460} my={30}>
            <img src="public/static/branaldi-logo.png" alt="Branaldi" width="100%" />
            <Paper radius="md" p="xl" withBorder {...props} pos="relative">
                <LoadingOverlay visible={loading} overlayBlur={2} />
                {props.message}
                <form onSubmit={form.onSubmit((values, event) => {
                    setLoading(true);
                    postRequest("/login", values).then((res) => {
                        props.state.context = res.context;
                        setLoading(false);
                    }).catch((e) => {
                        form.setErrors({password: e.msg});
                        setLoading(false);
                    });
                    event.preventDefault();
                })}>
                    <Stack>
                        <TextInput
                            required
                            label={<Translation id="email" />}
                            disabled={loading}
                            value={form.values.email}
                            onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                            error={form.errors.email}
                            radius="md"
                        />

                        <PasswordInput
                            required
                            label={<Translation id="password" />}
                            disabled={loading}
                            value={form.values.password}
                            onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                            error={form.errors.password}
                            radius="md"
                        />
                    </Stack>

                    <Group position="apart" mt="xl">
                        <Button type="submit" radius="xl" disabled={loading}>
                            <Translation id="login" />
                        </Button>
                    </Group>
                </form>
            </Paper>
        </Container>
    );
}

const Content = ({children, setMessage, state}: {children: any, setMessage: (message: any) => void, state: any}) => {
    useWindowEvent('focus', debounce(() => {
        getRequest("/components", {action: "Context.findOne"}).then((data) => {
            state.context = data;
            !data?.user?.id && setMessage(<Translation id="session_timed_out" />);
        });
    }, 300));

    useWindowEvent('blur', debounce(() => {
        getRequest("/components", {action: "Context.findOne"}).then((data) => {
            state.context = data;
            !data?.user?.id && setMessage(<Translation id="session_timed_out" />);
        });
    }, 300));

    useWindowEvent('mousemove', debounce(() => {
        getRequest("/components", {action: "Context.findOne"}).then((data) => {
            state.context = data;
            !data?.user?.id && setMessage(<Translation id="session_timed_out" />);
        });
    }, 1000 * 60 * 5));

    return children;
}

export default connect((state) => {
    return {
        state: state,
    }
})<{children: React.ReactNode}>(({children, state}: {children: React.ReactNode, state: any}): any => {

    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState(null);
    useEffect(() => {
        getRequest("/components", {action: "Context.findOne"}).then((data) => {
            state.context = data;
            setLoading(false);
        });
    }, []);

    if (!loading && state?.context?.user?.id) {
        return <Content setMessage={setMessage} state={state}>{children}</Content>;
    } else {
        return <>
            <LoadingOverlay visible={loading} overlayBlur={2} />
            <AuthenticationForm state={state} message={message} />
        </>
    }
});