import React from "react";
import Editor from "../Editor/Editor";
import List, {ListPropsType} from "./List";

export default React.forwardRef<any, Partial<ListPropsType&{[key: string]: any}>>((props, ref) => {
    return <List
        ref={ref}
        component="Option"
        fields={{
            "actions": {
                header: (_props) => {
                    return <Editor
                        {..._props}
                        children={null}
                        onSaved={() => {
                            _props.setState({..._props.state});
                        }}
                    />
                },
                filterable: false,
                sortable: false,
                width: 1,
                formatter: (props) => {
                    return <>
                        <Editor
                            {...props}
                            values={props.data}
                            {...{[props.config.idfield]: props?.data?.[props.config.idfield]}}
                            onSaved={() => {
                                props.setState({...props.state});
                            }}
                            onRemoved={() => {
                                props.setState({...props.state});
                            }}
                            children={null}
                        />
                    </>;
                }
            },
            /*"id": {
                filterable: true,
                sortable: true,
            },*/
            "value": {
                filterable: true,
                sortable: true,
                width: 1,
            },
            "optionname": {
                filterable: true,
                sortable: true,
            },
            "category": {
                filterable: true,
                sortable: true,
            },
            "label": {
                filterable: true,
                sortable: true,
            }
        }}
        {...props}
    />;
});