import React from "react";
import {
    Route,
    Routes, useParams,
} from "react-router-dom";
import Dashboard from "../Pages/Dashboard";
import DynamicComponent from "../Utils/DynamicComponent";
import {pascalCase} from "../../utils/string/change-case";

const Components = () => {
    let {components} = useParams();
    return <DynamicComponent
        path={"Pages/" + pascalCase(components)}
    />
}

export default () => {
    return <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/:components" element={<Components />} />
    </Routes>
}