import React, {useEffect} from "react";
import {getRequest} from "../../utils/request/request";
import {list} from "../../utils/array";
import {useForceUpdate} from "@mantine/hooks";
import PropsProxy from "./PropsProxy";
import Mustache from "mustache";
import {snakeCase} from "../../utils/string/change-case";

const translates = {};
const defaultBase = '';
const defaultLang: string = "hu";

type PropsType = {
    base?: string|string[],
    lang?: string,
    data?: any,
    fallback?: string,
}&({
    id: string,
    children?: undefined,
}|{
    id: string[],
    children: React.ReactNode
});

const requests = {};

const loadTranslation = ({base, id, lang, cb}: PropsType&{cb: () => void}) => {
    id = Array.isArray(id) ? id.map((id) => (id !== null && id !== undefined ? String(id) : id)) : (id !== null && id !== undefined ? String(id) : id);
    const bases = list(base).map((b) => b.split(".").map(b => snakeCase(b)).join("."));
    if (!bases.find((v) => v === defaultBase)) {
        bases.push(defaultBase);
    }
    const filter = [
        {field: "id", value: id === undefined ? "" : id, operator: Array.isArray(id) ? "in" : "="},
        {field: "base", value: bases, operator: "in"}
    ];
    const hash = JSON.stringify(filter);
    let req;
    if (requests[hash]) {
        req = requests[hash];
    } else {
        requests[hash] = req = getRequest("/components", {
            action: "Translation.translate",
            filter,
            itemsperpage: 0,
        });
    }
    req.then((res) => {
        delete requests[hash];
        res.items.forEach((translate) => {
            translates[translate.id] = {...(translates[translate.id] ?? {}), [translate.base]: translate};
        });
        /*if (!res.items.length) {
            console.log(id, bases, JSON.parse(JSON.stringify(translates)));
        }*/
        /*bases.forEach((base) => {
            list(id).forEach((id) => {
                if (!translates?.[id]?.[defaultBase]?.[defaultLang]) {
                    translates[id] = {
                        ...(translates[id] ?? {}),
                        [defaultBase]: {[defaultLang]: translates?.[id]?.[defaultBase]?.[defaultLang] ?? id}
                    };
                }
            });
        });*/
        cb && cb();
    }).catch(() => {
        delete requests[hash];
    });
}

const get = ({base, id, lang}: Omit<PropsType, 'id'>&{id: string}) => {
    id = String(id);
    const bases = list(base).map((b) => b.split(".").map(b => snakeCase(b)).join("."));
    if (!bases.find((v) => v === defaultBase)) {
        bases.push(defaultBase);
    }
    for (let base of bases) {
        if (translates?.[id]?.[base]?.[lang ?? defaultLang]) {
            return translates?.[id]?.[base]?.[lang ?? defaultLang];
        }
    }
    return translates?.[id]?.[defaultBase]?.[defaultLang];
}


export default ({base, id, lang, children, data, fallback}: PropsType) => {
    const forceUpdate = useForceUpdate();
    if (!Array.isArray(id)) {
        const t = get({base, id, lang});
        useEffect(() => {
            if (!t) {
                loadTranslation({base, id, lang, cb: forceUpdate});
            }
        }, [id]);
        return Mustache.render(String(t ?? fallback ?? id), data);
    } else {
        const notLoaded = [];
        const ts = id.reduce((all, id) => {
            const loaded = get({base, id, lang});
            if (loaded === undefined) {
                notLoaded.push(id);
            }
            return {
                ...all,
                [id]: Mustache.render(String(loaded ?? fallback ?? id), data)
            }
        }, {});
        useEffect(() => {
            if (notLoaded.length) {
                loadTranslation({base, id: notLoaded, lang, cb: forceUpdate, children: null});
            }
        }, [notLoaded.join(",")]);
        return <PropsProxy translations={ts}>
            {children}
        </PropsProxy>;
    }
}