import React from "react";
import {FieldPropsType} from "../../Editor";
import {forwardRef} from "react";
import ComponentSelect from "./ComponentSelect";
import {Group, Text} from "@mantine/core";
import Translation from "../../../Utils/Translation";

export * from "./ComponentSelect";

const SelectItem = forwardRef<HTMLDivElement, any>(
    ({ data: {lastname, firstname, email, phone}, ...others }: any, ref) => {
        return <div ref={ref} {...others}>
            <Group noWrap>
                <div>
                    <Text size="sm">{lastname} {firstname}</Text>
                    <Text size="xs" opacity={0.65}>
                        <Translation id="email"/>: {email}
                        <br/>
                        <Translation id="phone"/>: {phone}
                    </Text>
                </div>
            </Group>
        </div>
    }
);

export default (props: FieldPropsType) => {
    return <ComponentSelect
        {...props}
        labelField={({lastname, firstname}) => `${lastname} ${firstname}`}
        itemComponent={SelectItem}
    />
}