import React, {Fragment, useCallback, useState} from "react";
import Editor from "../Editor/Editor";
import List, {ListPropsType} from "./List";
import * as stylex from "../Utils/StyleInjector";
import classNames from "classnames";
import {Button, Modal} from "@mantine/core";
import Translation from "../Utils/Translation";
import ComponentConfig from "../Utils/ComponentConfig";
import DynamicComponent from "../Utils/DynamicComponent";
import {IconInfoSquare} from "@tabler/icons-react";

const percentColors = [
    {pct: 0.0, color: {r: 235, g: 204, b: 204}},
    {pct: 0.5, color: {r: 250, g: 242, b: 204}},
    {pct: 1.0, color: {r: 208, g: 233, b: 198}}
];

const getColorForPercentage = function (pct) {
    let i = 1;
    for (; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
            break;
        }
    }
    let lower = percentColors[i - 1];
    let upper = percentColors[i];
    let range = upper.pct - lower.pct;
    let rangePct = (pct - lower.pct) / range;
    let pctLower = 1 - rangePct;
    let pctUpper = rangePct;
    return {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
}

const styles = stylex.create({
    ...[0,100,200,300,400,500,600].reduce((all, state, currentIndex, array) => {
        return {...all, [`state-${state}`]: {
                backgroundColor: {
                    default: 'rgba(' + Object.values(getColorForPercentage(currentIndex / array.length)).join(',') + ',0.8)',
                    ":hover": 'rgb(' + Object.values(getColorForPercentage(currentIndex / array.length)).join(',') + ') !important',
                }
            }}
    }, {})
});

function emptyComment(text: string|null|undefined) {
    return text === undefined || text === null || text.trim().replace(/\s+/g, '').replace(/<p><\/p>/g, '').replace(/<p\/>/g, '') === "";
}

export default stylex.inject(styles)(React.forwardRef<any, Partial<ListPropsType&{[key: string]: any}>>((props, ref) => {
    const Comment = useCallback(function ({data, value}) {
        const [opened, setOpened] = useState(false);
        if (!emptyComment(data.comment) || data.comments?.some?.(({text}) => !emptyComment(text))) {
            return <>
                <Modal
                    size="lg"
                    title={<Translation base={[]} id="comments" />}
                    opened={opened}
                    centered
                    overlayProps={{
                        blur: 1,
                    }}
                    onClose={() => setOpened(false)}
                >
                    {!emptyComment(data?.comment) ? <ComponentConfig component="Project">
                        <DynamicComponent
                            session={props.session}
                            path="Editor/Fields/belongsTo/RichText"
                            field="comment"
                            action="create"
                            value={undefined}
                            disabled={true}
                            readOnly={true}
                            form={{
                                values: data,
                                errors: {}
                            }}
                        />
                    </ComponentConfig> : null}
                    {data.worksheets.map((worksheet, idx) => {
                        return <Fragment key={idx}>
                            {worksheet.comments?.map((comment, idx) => {
                                if (emptyComment(comment.text)) return null;
                                return <ComponentConfig key={idx} component="Comment">
                                    <DynamicComponent
                                        session={props.session}
                                        path="Editor/Fields/belongsTo/RichText"
                                        field="text"
                                        action="create"
                                        value={undefined}
                                        disabled={true}
                                        readOnly={true}
                                        label={`${worksheet.pid}: ${comment?.owner?.lastname} ${comment?.owner?.firstname}`}
                                        form={{
                                            values: comment,
                                            errors: {}
                                        }}
                                    />
                                </ComponentConfig>;
                            })}
                        </Fragment>
                    })}
                </Modal>
                <Button
                    onClick={() => setOpened(true)}
                >
                    <IconInfoSquare/>
                </Button>
            </>;
        }
        return null;
    }, [props.session]);
    return <>
        <List
            ref={ref}
            component="Project"
            rowClassName={({element}) => {
                return stylex.props(styles[`state-${element.state}`]).className;
            }}
            fields={{
                "actions": {
                    header: (_props) => {
                        return <Editor
                            {..._props}
                            children={null}
                            onSaved={() => {
                                _props.setState({..._props.state});
                            }}
                        />
                    },
                    filterable: false,
                    sortable: false,
                    width: 1,
                    formatter: (_props) => {
                        return <>
                            <Editor
                                {..._props}
                                {...{[_props.config.idfield]: _props?.data?.[_props.config.idfield]}}
                                onSaved={() => {
                                    if (!props.value) {
                                        _props.setState({..._props.state});
                                    }
                                }}
                                onRemoved={() => {
                                    if (!props.value) {
                                        _props.setState({..._props.state});
                                    }
                                }}
                                values={_props.data}
                                children={null}
                            />
                        </>;
                    }
                },
                "id": {
                    filterable: true,
                    sortable: true,
                    width: 1,
                },
                "label": {
                    filterable: true,
                    sortable: true,
                },
                "partner": {
                    filterable: true,
                    sortable: true,
                },
                "customer": {
                    filterable: true,
                    sortable: true,
                },
                "qty": {
                    filterable: true,
                    sortable: true,
                },
                "deadline": {
                    filterable: true,
                    sortable: true,
                },
                "state": {
                    filterable: true,
                    sortable: true,
                    width: 15,
                },
                "comment": {
                    filterable: false,
                    sortable: false,
                    formatter: Comment
                }
            }}
            {...props}
        />
    </>
}));