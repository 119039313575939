import React from "react";
import {Title} from "@mantine/core";
import Translation from "../Utils/Translation";
import ProjectList from "../List/ProjectList";
import CheckPrivilege from "../Utils/CheckPrivilege";
export default () => <CheckPrivilege component="Project">
    <Title order={1}>
        <Translation id="projects" />
    </Title>
    <ProjectList listId="projects" />
</CheckPrivilege>;