import React, {useEffect, useRef, useState} from "react";
import Translation from "../../../Utils/Translation";
import {TextInput, Autocomplete} from "@mantine/core";
import {FieldPropsType} from "../../Editor";
import useReadOnly from "../useReadOnly";
import {getRequest} from "../../../../utils/request/request";
import {useDebouncedValue} from "@mantine/hooks";
import {uniqueFilter} from "../../../../utils/array";
import useIsInViewport from "../../../Utils/useIsInViewport";

export const view = null;

export default (props: FieldPropsType) => {
    const form = props.form;
    const field = props.field;
    const bases = [props.config.componentName + ".field." + props.field, props.config.componentName];
    const fieldReadOnly = useReadOnly(props);
    const fieldSettings = props.config?.fields?.[props.field];
    let FieldType: any = TextInput;
    let fieldProps: any = {
        onChange: (event) => form.setFieldValue(field, event.currentTarget.value.replace(/\s+/gm, ' '))
    };
    const value = form.values?.[field] ?? '';
    const [options, setOptions] = useState([]);
    const [search] = useDebouncedValue(value, 300);
    useEffect(() => {
        if (fieldReadOnly) {
            setOptions([]);
            return;
        }
        if (fieldSettings.autoComplete) {
            const req = getRequest("/components", {
                option: props.config.componentName,
                // filter: {field, value: search + "%", operator: "LIKE"}
                filter: {
                    filters: search.split(/\s+/).map((search) => ({
                        field: field,
                        value: `%${search}%`,
                        operator: "LIKE"
                    })),
                    operator: "OR"
                },
                itemsperpage: 0,
            });
            req.then((data) => {
                setOptions(data.items.map((item) => item[props.field]).filter(uniqueFilter));
            });
            return () => {
                req.abort();
            }
        }
    }, [props.field, fieldSettings?.autoComplete, search]);
    const ref = useRef(null);
    const isInViewport = useIsInViewport(ref);
    useEffect(() => {
        if (props.readOnly) return;
        if (fieldSettings.calculated) {
            if (!form.values[props.config.idfield]) {
                const f = new Function("'use strict'; return " + fieldSettings.calculated.calculator)();
                const newValue = f(form.values);
                if (newValue !== value) {
                    form.setValues({[field]: newValue});
                    form.resetDirty({[field] : newValue});
                }
            } else {
                const r = getRequest("/components", {
                    action: props.config.componentName + ".findOne",
                    [props.config.idfield]: form.values[props.config.idfield],
                    view: fieldSettings.calculated.view,
                });
                r.then((values) => {
                    const f = new Function("'use strict'; return " + fieldSettings.calculated.calculator)();
                    const newValue = f(values);
                    if (newValue !== value) {
                        form.setValues({[field]: newValue});
                        form.resetDirty({[field] : newValue});
                    }
                });
                return () => {
                    r.abort();
                }
            }
        }
    }, [form.values, value, isInViewport, props.readOnly]);
    if (fieldSettings?.autoComplete) {
        FieldType = Autocomplete;
        fieldProps.data = options;
        fieldProps.onChange = (value) => form.setFieldValue(field, value.replace(/\s+/gm, ' '));
    }
    return <FieldType
        ref={ref}
        name={field}
        label={<Translation base={bases} id={field} />}
        withAsterisk={props.config?.fields?.[field]?.required}
        value={value}
        placeholder={Translation({base: bases, id: field + ".placeholder", fallback: ""})}
        {...fieldProps}
        error={form.errors?.[field]}
        readOnly={fieldSettings.virtual ? true : fieldReadOnly || props.readOnly}
        radius="md"
    />
}