import {FieldPropsType} from "../../Editor";
import {Autocomplete, Button, Input, Table} from "@mantine/core";
import Translation from "../../../Utils/Translation";
import React from "react";
import Confirm, {deleteProps} from "../../../Utils/Confirm";
import {IconTrash, IconPlus} from "@tabler/icons-react";
import useReadOnly from "../useReadOnly";

export const view = null;

export default (props: FieldPropsType) => {
    const form = props.form;
    const field = props.field;
    const bases = [props.config.componentName + ".field." + props.field, props.config.componentName];
    const fieldReadOnly = useReadOnly(props);
    const readOnly = fieldReadOnly ?? props.readOnly;
    return <Input.Wrapper
        label={<Translation base={bases} id={field} />}
        withAsterisk={props.config?.fields?.[field].required}
        error={form.errors?.[field]}
    >
        <Table verticalSpacing="xs" highlightOnHover withBorder withColumnBorders>
            <thead>
                <tr>
                    {!readOnly ? <th /> : null}
                    {props.config.fields[field].headers.map((field: string) => {
                        return <th
                            key={field}
                        >
                            <Translation id={field} />
                        </th>;
                    })}
                </tr>
            </thead>
            <tbody>
            {form.values?.[props.field]?.map((row: any[], rowIdx: number) => {
                return <tr
                    key={rowIdx}
                >
                    {!readOnly ? <td style={{padding: 0}}>
                        <Confirm
                            {...deleteProps}
                            onAccept={() => {
                                const value = JSON.parse(JSON.stringify(form.values[field]));
                                value.splice(rowIdx, 1);
                                form.setFieldValue(field, value);
                            }}
                        >
                            <Button
                                variant="subtle"
                            >
                                <IconTrash />
                            </Button>
                        </Confirm>
                    </td> : null}
                    {props.config.fields[field].headers.map((name: string, columnIdx: number) => {
                        let InputType: any = Input;
                        let fieldProps: any = {
                            onChange: (event) => {
                                const value = JSON.parse(JSON.stringify(form.values[field]));
                                value[rowIdx][columnIdx] = event.currentTarget.value.replace(/\s+/gm, ' ');
                                form.setFieldValue(field, value);
                            }
                        }
                        if (props.config.fields[field].suggestion?.[name]) {
                            InputType = Autocomplete;
                            fieldProps.data = props.config.fields[field].suggestion?.[name];
                            fieldProps.onChange = (v) => {
                                const value = JSON.parse(JSON.stringify(form.values[field]));
                                value[rowIdx][columnIdx] = v.replace(/\s+/gm, ' ');
                                form.setFieldValue(field, value);
                            }
                        }
                        return <td
                            key={columnIdx}
                            width={String(100 / props.config.fields[field].headers.length) + "%"}
                            style={{padding: 0}}
                        >
                            <InputType
                                ref={(ref) => {
                                    if (ref) {
                                        ref.style.textAlign = columnIdx > 0 && columnIdx < props.config.fields[field].headers.length - 1 ? "center" : "left";
                                    }
                                }}
                                style={{
                                    textAlign: columnIdx > 0 && columnIdx < props.config.fields[field].headers.length - 1 ? "center" : "left",
                                }}
                                variant="unstyled"
                                value={row[columnIdx] ?? ""}
                                readOnly={readOnly}
                                {...fieldProps}
                            />
                        </td>
                    })}
                </tr>;
            })}
                {!readOnly ? <tr>
                    <td
                        colSpan={props.config.fields[field].headers.length + 1}
                        style={{padding: 0}}
                    >
                        <Button
                            variant="subtle"
                            fullWidth
                            onClick={() => {
                                const value = JSON.parse(JSON.stringify(form.values[field] ?? []));
                                value.push(Array(props.config.fields[field].headers.length).fill(""));
                                form.setFieldValue(field, value);
                            }}
                        >
                            <IconPlus />
                        </Button>
                    </td>
                </tr> : null}
            </tbody>
        </Table>
    </Input.Wrapper>
}