import {proxy, subscribe} from 'valtio';

//initialize throttlePause variable outside throttle function
let throttlePause;
const throttle = (callback, time) => {
    return () => {
        //don't run the function if throttlePause is true
        if (throttlePause) return;
        //set throttlePause to true after the if condition. This allows the function to be run once
        throttlePause = true;

        //setTimeout runs the callback within the specified time
        setTimeout(() => {
            callback();

            //throttlePause is set to false once the function has been called, allowing the throttle function to loop
            throttlePause = false;
        }, time);
    }
};

export default <T extends any = any>({
    name,
    initialState,
    getStorage = () => localStorage
}: {
    name: string,
    initialState: any
    getStorage?: () => Storage
}): T => {
    const storage = getStorage();
    const state = proxy(JSON.parse(storage.getItem(name)) || initialState)

    subscribe(state, throttle(() => {
        storage.setItem(name, JSON.stringify(state))
    }, 1000));

    return state;
}