import React from "react";
import {Title} from "@mantine/core";
import Translation from "../Utils/Translation";
import UserList from "../List/UserList";
import CheckPrivilege from "../Utils/CheckPrivilege";

export default () => <CheckPrivilege component="User">
    <Title order={1}>
        <Translation id="users" />
    </Title>
    <UserList listId="users" />
</CheckPrivilege>;