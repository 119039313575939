import http_build_query from "locutus/php/url/http_build_query";
import objectToFormData from "./object-to-formdata";
import axios from "axios";
import contentDisposition from "content-disposition/index";
import jsonp from './jsonp';
import parse_url from "locutus/php/url/parse_url";
import {unparse_url} from "../url/unparse_url";
import parse_str from "locutus/php/strings/parse_str";
import PromiseRequest from "../promise/PromiseRequest";

type UrlType = {
    scheme?: string,
    host?: string,
    path?: string,
    query?: string|object,
    fragment?: string,
    user?: string,
    pass?: string
};

export const UrlGenerator = (url: string|null|UrlType = null) => {
    let href: UrlType;
    if (url === null) {
        href = parse_url(window.location.href);
        if (href.query) {
            let query = {};
            parse_str(href.query, query);
            href.query = query;
        }
    } else if (typeof url === "string") {
        href = parse_url(url);
    } else {
        href = {...url};
    }
    return new Proxy(href, {
        get(target: UrlType, p: string | symbol, receiver: any): any {
            if (p === 'toString') {
                return function() {
                    let url = {...target};
                    if (typeof url.query === "object") {
                        url.query = paramsSerializer(url.query);
                    }
                    return unparse_url(url);
                };
            } else {
                return Reflect.get(target, p, receiver);
            }
        },
        set(target: UrlType, p: string | symbol, value: any, receiver: any): boolean {
            if (p === 'query' && typeof value === "string") {
                let query = {};
                parse_str(value, query);
                value = query;
            }
            return Reflect.set(target, p, value, receiver);
        }
    });
}

const requestConfig = {nullsAsUndefineds: '_null', emptyArrayAs: '_array', indices: true};

class requestObj {
    obj: {[key: string]: any} = {};
    append = (key: string, value: any) => {
        this.obj[key] = value;
    }
}

export const paramsSerializer = (params: any) => {
    const getObj = new requestObj();
    params = JSON.parse(JSON.stringify(params));
    params = objectToFormData(params, requestConfig, getObj).data.obj;
    return http_build_query(params);
};

type OptionsType = {
    url?: string,
    paramParser?: (params: any) => any,
    contentType?: string,
    customHeaders?: {[key: string]: any},
    headers?: boolean
};

export const rawRequest = <ResponseType extends any = any,>(url: string, method: string, requestParams: {}, postParams: {}, options: OptionsType): PromiseRequest<ResponseType> => {
    let promise: any = null;
    let isCatching = () => {
        return !promise.hasCatch();
    }

    const controller = new AbortController();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const requestId = "r" + new Date().getTime();
    //store.dispatch(startPace(requestId));

    const data = ((options) => {
        if (options.paramParser) {
            return options.paramParser(postParams);
        } else {
            const obj = objectToFormData(postParams, requestConfig);
            if (obj.flags.containsFile) {
                options.contentType = options.contentType || 'multipart/form-data';
                return obj.data;
            } else {
                options.contentType = options.contentType || 'application/json';// or 'application/json;charset=utf-8';
                return postParams;
            }
        }
    })(options);

    let headers = {};
    if (options.customHeaders) {
        headers = options.customHeaders;
    } else if (options.headers !== false) {
        headers = { 'Content-Type': options.contentType || 'multipart/form-data', 'X-Requested-With': 'XMLHttpRequest' };
    }

    const urlParams = parse_url(url);

    const request = axios({
        adapter: urlParams.host === undefined || urlParams.host === window.location.hostname ? undefined : jsonp,
        //callbackParamName: urlParams.host === undefined || urlParams.host === window.location.hostname ? undefined : "callback",
        onUploadProgress: (progressEvent) => {
            let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            //store.dispatch(setPacePercent(requestId, null, percentCompleted));
        },
        onDownloadProgress: (progressEvent) => {
            let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            //store.dispatch(setPacePercent(requestId, percentCompleted, null));
        },
        cancelToken: source.token,
        signal: controller.signal,
        method: method,
        url,
        timeout: 1000 * 60 * 5, // 5 minutes
        headers,
        paramsSerializer: (params) => {
            const getObj = new requestObj();
            params = JSON.parse(JSON.stringify(params));
            params = options.paramParser ? options.paramParser(params) : objectToFormData(params, requestConfig, getObj).data.obj;
            return http_build_query(params);
        },
        params: {...requestParams, '_': new Date().getTime()}, // for no cache
        data,
        responseType: 'blob', // important
    });
    promise = new PromiseRequest((resolve, reject, signal) => {
        signal.onAbort = () => {
            source.cancel('Operation canceled by the user.');
        }
        request.then((response) => {
            const finish = (response: any) => {
                resolve(response.data);
            }
            let disposition = null;
            let type = null;
            Object.keys(response.headers).map((key) => {
                if (key.toLowerCase() === "content-disposition") {
                    disposition = contentDisposition.parse(response.headers[key])?.parameters?.filename || "download";
                }
                if (key.toLowerCase() === "content-type") {
                    type = response.headers[key];
                }
            });
            if (disposition && type) {
                response.data.filename = disposition;
                finish(response);
            } else {
                const reader = new FileReader();
                reader.onload = () => {
                    /*if (type.indexOf("json") !== -1 || type === null) {
                        response.data = JSON.parse(reader.result);
                    } else {
                        response.data = reader.result;
                    }*/
                    try {
                        response.data = JSON.parse(reader.result as string);
                    } catch (e) {
                        response.data = reader.result;
                    }
                    finish(response);
                };
                reader.readAsText(response.data);
            }
        }).catch((e) => {
            //store.dispatch(endPace(requestId));
            if (!axios.isCancel(e)) {
                const finish = (e: any) => {
                    Object.defineProperty(e.response.data, 'statusCode', {
                        enumerable: false,
                        configurable: false,
                        get: () => e.response.status,
                        set: () => {throw new Error("statusCode is immutable!")}
                    });
                    reject(e.response.data);
                }
                const reader = new FileReader();
                reader.onload = () => {
                    /*if (type.indexOf("json") !== -1 || type === null) {
                        response.data = JSON.parse(reader.result);
                    } else {
                        response.data = reader.result;
                    }*/
                    try {
                        e.response.data = JSON.parse(reader.result as string);
                    } catch (_e) {
                        e.response.data = reader.result;
                    }
                    finish(e);
                };
                reader.readAsText(e.response.data);
            }
        });
    });
    return promise;
}

const requestCore = <ResponseType extends any = any,>(url: string, method: string, requestParams: any, postParams: any, options: OptionsType): PromiseRequest<ResponseType> => {
    let href = parse_url(window.location.href);
    delete href.fragment;
    href = unparse_url(href);
    if (!href.endsWith("/")) {
        href += "/";
    }
    return rawRequest<ResponseType>(url, method, requestParams, postParams, options);
}

export const requestBase = <ResponseType extends any = any>(url: string, method: string, requestParams: any, postParams: any, options: OptionsType): PromiseRequest<ResponseType> => {
    options = options || {};
    return requestCore<ResponseType>(url, method, requestParams, postParams, options);
}

export const fileUpload = <ResponseType extends any = any>(url: string, actionparams: any, params = {}, options: OptionsType = {}): PromiseRequest<ResponseType> => {
    return requestCore<ResponseType>(url, "post", params, actionparams, options);
}

export const getRequest = <ResponseType extends any = any>(url: string, params: any = {}, options: OptionsType = {}): PromiseRequest<ResponseType> => {
    return requestBase<ResponseType>(url, "get", params, {}, options);
}
export const postRequest = <ResponseType extends any = any>(url: string, actionparams: any = {}, requestparams = {}, options: OptionsType = {}): PromiseRequest<ResponseType> => {
    return requestBase<ResponseType>(url, "post", requestparams, actionparams, options);
}