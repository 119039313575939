export type CommonFilterObjectType = {
    field?: string,
    operator?: string|"in"|"="|"!="|"<"|">"|"<="|">="|"between"|"like"|"isnull"|"not isnull",
    matchmode?: string|"start"|"end"|"any"|"oneof"|"allof"|"search"|"exact",
    value?: number|string|boolean|(number|string|boolean)[],
    expression?: string,
    expressions?: [string|number,string|number],
    negate?: boolean,
};

export type CommonFilterList = CommonFilterObjectType[];
export type CommonFiltersObject = {
    filters: CommonFilterObjectType[]|CommonFiltersType[],
    operator?: "AND"|"OR",
    negate?: boolean,
};

export type CommonFiltersType = CommonFilterList|CommonFiltersObject;
export type FilterType = CommonFilterObjectType|CommonFiltersType;

export const isFilters = (filter: any): filter is CommonFiltersType => {
    return Array.isArray(filter) || (filter && filter instanceof Object && filter.filters);
}
export const isFilter = (filter: any): filter is CommonFilterObjectType => {
    return filter && filter instanceof Object && !filter.filters;
}