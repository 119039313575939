import React from "react";
import {Title} from "@mantine/core";
import Translation from "../Utils/Translation";
import OptionList from "../List/OptionList";
import CheckPrivilege from "../Utils/CheckPrivilege";

export default () => <CheckPrivilege component="Option">
    <Title order={1}>
        <Translation id="options" />
    </Title>
    <OptionList listId="options" />
</CheckPrivilege>;