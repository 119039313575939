import {useEffect, useState} from "react";
import {getRequest} from "../../utils/request/request";

export default ({children, component}: {children: any, component: string}) => {
    const [hasAccess, setHasAccess] = useState(component ? null : true);
    useEffect(() => {
        if (hasAccess !== null && hasAccess !== undefined) return;
        if (component) {
            getRequest("/components", {action: component + ".find", limit: 1, view: {}}).then((res) => {
                setHasAccess(true);
            }).catch(() => {
                setHasAccess(false);
            });
        } else {
            setHasAccess(true);
        }
    }, [hasAccess, component]);
    if (hasAccess === true) {
        return children;
    }
    return null;
}