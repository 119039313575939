import React, {useEffect, useState} from "react";
import {useStateContext} from "./index";
import {subscribe} from "valtio";

export default (derive: (state: any) => {[key: string]: ProxyConstructor|null|string|number}) => {
    return <P extends Record<string, any>>(Component: React.ComponentType<P>): React.ForwardRefExoticComponent<P> => {
        const connect = React.forwardRef((props, ref) => {
            const state = useStateContext();
            const [next, setNext] = useState(0);
            useEffect(() => {
                return subscribe(state, () => {
                    setNext((next + 1) % 2);
                });
            });
            const derivedProps = derive(state);
            return <Component ref={ref} {...derivedProps} {...props as P} />;
        });
        connect.displayName = "valtioConnect";
        return connect as React.ForwardRefExoticComponent<P>;
    }
}