import {FieldPropsType} from "../../Editor";
import React, {Fragment} from "react";
import {get} from "../../../../utils/object";
import {Button} from "@mantine/core";
import {IconPlus, IconTrash} from "@tabler/icons-react";
import Confirm, {deleteProps} from "../../../Utils/Confirm";
import useComponent from "../../../../utils/component/useComponent";

export default (props: FieldPropsType) => {
    const form = props.form;
    const field = props.field;
    const currentField = field.split(".").shift();
    const fieldSettings = props.config?.fields?.[currentField];
    const value = get(form.values, field);
    const [ListComponent] = useComponent({
        path: "Editor/Fields/Lists/" + fieldSettings.datatype
    });
    const [Component] = useComponent({
        path: "Editor/Fields/" + fieldSettings.datatype
    });
    return (
        ListComponent ? <ListComponent
            {...props}
        /> : <>
            {value?.map((_null, idx) => {
                if (!Component) return null;
                return <Fragment key={idx}>
                    <Confirm
                        {...deleteProps}
                        onAccept={() => {
                            const newValue = [...value];
                            newValue.splice(idx, 1);
                            form.setFieldValue(field, newValue);
                        }}
                    >
                        <Button
                            variant="filled"
                        >
                            <IconTrash />
                        </Button>
                    </Confirm>
                    <Component
                        key={idx}
                        {...props}
                        field={field + "." + idx}
                    />
                </Fragment>
            })}
            <Button
                variant="filled"
                onClick={() => {
                    form.setFieldValue(field, [...value, null]);
                }}
            >
                <IconPlus />
            </Button>
        </>
    )
}