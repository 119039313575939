import React, {useCallback, useEffect, useRef, useState} from "react";
import {Box, Button, Group, LoadingOverlay, Modal, Text} from "@mantine/core";
import Translation from "../Utils/Translation";
import {IconPencil, IconPlus, IconTrash} from "@tabler/icons-react";
import {getRequest, postRequest} from "../../utils/request/request";
import Confirm, {deleteProps} from "../Utils/Confirm";
import PropsProxy from "../Utils/PropsProxy";
import {useForm, UseFormReturnType} from "../Utils/useForm";
import moment from "moment";
import DynamicComponent from "../Utils/DynamicComponent";
import {connect} from "../../valtio";
import get from "../../utils/object/get";
import filter from "../../utils/filter";
import hasActionPrivilege from "../../utils/component/hasActionPrivilege";
import {withComponentConfig} from "../Utils/ComponentConfig";
import {ComponentConfigFieldType, ComponentConfigType} from "../../utils/component/ComponentConfig";
import sequence from "../../utils/promise/sequence";
import {useEffectOnMount} from "../Utils/useEffectOnMount";
import list from "../../utils/array/list";

export type ActionType = (args: {props: EditorPropsType, request: {action: string, data?: any, [key: string]: any}}) => any;

export type EditorPropsType = {
    ref?: React.Ref<typeof EditorComp>,
    config: ComponentConfigType,
    disabledActions?: string[],
    opened?: boolean,
    dirtyValues?: boolean,
    valuesParser?: (data: any) => typeof data,
    values?: any,
    id?: any,
    onClose?: () => void,
    onSaved?: () => void,
    onRemoved?: () => void,
    buttons?: React.ReactNode|false,
    children?: any,
    actionButtons?: any,
    exists?: (props: EditorPropsType&{form: UseFormReturnType<any>}) => boolean,
    actions?: {
        create?: ActionType,
        update?: ActionType,
        remove?: ActionType,
        findOne?: ActionType,
        [key: string]: ActionType,
    },
    editor?: any,
    form?: any,
    readOnly?: boolean,
}

export type FieldPropsType = {
    form: UseFormReturnType<any>,
    action: string,
    field: string,
    readOnly?: boolean,
    session?: {context: any},
    label?: any,
} & EditorPropsType;

export type FormPropsType = {fields: {[key: string]: React.ReactNode}, form?: UseFormReturnType<any>}&EditorPropsType;

const DefaultForm = ({fields}: FormPropsType) => {
    return <>{Object.values(fields)}</>;
}

const ConfirmClose = ({onDiscard, opened, setOpened, onSave}: {onDiscard: () => void, opened: boolean, setOpened: (opened) => void, onSave: () => void}) => {
    return <>
        <Modal
            title={<Translation id="save" />}
            opened={opened}
            centered
            onClose={() => setOpened(false)}
        >
            Akarod menteni a változtatásokat?
            <Group mt="xl" position="right">
                <Button
                    variant="default"
                    onClick={async () => {
                        setOpened(false)
                    }}
                >
                    <Translation id="cancel" />
                </Button>
                <Button
                    variant="default"
                    onClick={async () => {
                        await onDiscard();
                    }}
                >
                    <Translation id="discard" />
                </Button>
                <Button
                    type="submit"
                    onClick={async () => {
                        await onSave();
                    }}
                >
                    <Translation id="save" />
                </Button>
            </Group>
        </Modal>
    </>
}

const createValues = async (props: EditorPropsType&{values?: any}&{session?: {context: any}}) => {
    const data = Object.entries(props.config?.fields || {}).reduce((all, [field, fieldSettings]: [string, any]) => {
        let value = props?.values?.[field];
        switch (fieldSettings.datatype) {
            case "Boolean":
                if (!props.values && fieldSettings.defaultValue !== undefined) {
                    value = fieldSettings.defaultValue ? "1" : "0";
                }
                break;
            case "DateTime":
                if (!props.values && fieldSettings.defaultValue !== undefined) {
                    value = fieldSettings.defaultValue === "now" ? moment().format("YYYY-MM-DD HH:mm:ss") : fieldSettings.defaultValue;
                }
                break;
            default:
                if (!props.values && fieldSettings.defaultValue !== undefined) {
                    if (fieldSettings.defaultValue instanceof Object && fieldSettings.defaultValue.componentValue) {
                        const fieldNames = fieldSettings.defaultValue.componentValue.split(".");
                        const component = fieldNames.shift();
                        const field = fieldNames.join(".");
                        if (component === "Context") {
                            value = get(props.session.context, field);
                            if (value && value instanceof Object) {
                                value = JSON.parse(JSON.stringify(value));
                            }
                        } // TODO other component field
                    } else {
                        value = fieldSettings.defaultValue;
                    }
                }
                break;
        }
        return {
            ...all,
            [field]: value,
        }
    }, {});
    return props.valuesParser ? props.valuesParser(data) : data;
}

const EditorComp = (props: EditorPropsType&{session?: {context: any}, view: Record<string, any>}) => {
    const view = props.view;
    const [dirtyModal, setDirtyModal] = useState(false);
    const [initialValues, setInitialValues] = useState(null);
    const [loadingValues, setLoadingValues] = useState(true);
    useEffect(() => {
        setLoadingValues(true);
        createValues(props).then((values) => {
            setInitialValues(values);
            setLoadingValues(false);
        });
    }, [JSON.stringify(props.config?.fields)]);
    useEffectOnMount(() => {
        createValues(props).then((values) => {
            setInitialValues(values);
            setLoadingValues(false);
        });
    });
    let form = useForm({
        initialValues,
        validate: Object.entries(props.config?.fields || {}).reduce((all, [field, settings]: [string, any]) => ({
            ...all,
            [field]: (value, values/*, rulePath*/) => {
                if (settings.visible === false) return undefined;
                if (settings.visible && settings.visible !== true) {
                    if (!filter({
                        filter: JSON.parse(JSON.stringify(settings.visible)),
                        data: JSON.parse(JSON.stringify(form.values))
                    })) {
                        return undefined;
                    }
                }
                if (settings.required) {
                    if (settings.required === true || !filter({
                        filter: JSON.parse(JSON.stringify(settings.required)),
                        data: JSON.parse(JSON.stringify(values))
                    })) {
                        return !(value !== null && value !== undefined && value !== '')
                    }
                }
                return undefined;
            },
        }), {}),
    });

    const hasPrivilege = useCallback((action) => {
        return hasActionPrivilege({
            config: props.config,
            action,
            data: JSON.parse(JSON.stringify(form.values))
        })
    }, [form.values, props.config, props.session]);
    const readOnly = !hasPrivilege(form.values?.[props.config?.idfield] ? "update" : "create");

    const fields = Object.entries(props.config?.fields || {}).reduce((all, [field, settings]: [string, ComponentConfigFieldType]) => {
        if (settings.visible === false) return all;
        if (settings.visible && settings.visible !== true) {
            if (!filter({
                filter: JSON.parse(JSON.stringify(settings.visible)),
                data: JSON.parse(JSON.stringify(form.values))
            })) {
                return all;
            }
        }
        return {
            ...all,
            [field]: <DynamicComponent
                key={field}
                path={"Editor/Fields/" + settings.connection.type + "/" + settings.datatype}
                {...props}
                field={field}
                readOnly={props.readOnly || readOnly}
                action={action}
                form={form}
            />
        };
    }, {});
    const [opened, _setOpened] = useState(props.opened ?? (props.buttons === false || false));
    //console.log(initialValues, form.values, form.getInputProps("orderdate"));
    const setOpened = useCallback((opened) => {
        _setOpened(opened);
        if (!opened && props.onClose) {
            props.onClose();
        }
    }, [_setOpened]);
    useEffect(() => {
        setOpened(props.opened ?? (props.buttons === false || false));
    }, [props.opened, props.buttons]);
    useEffect(() => {
        if (opened) {
            setLoadingValues(true);
            let after;
            if (props.actions?.findOne) {
                after = Promise.resolve(props.actions.findOne({
                    request: {
                        action: props.config.componentName + ".findOne",
                        [props.config.idfield]: props[props.config.idfield],
                        view,
                    },
                    props
                }));
            } else {
                if (props[props.config.idfield]) {
                    after = getRequest("/components", {
                        action: props.config.componentName + ".findOne",
                        [props.config.idfield]: props[props.config.idfield],
                        view,
                    });
                } else {
                    after = createValues({...props});
                }
            }
            after.then(async (initialValues) => {
                setInitialValues(initialValues ?? {});
                form.setValues(await createValues({...props}));
                setLoadingValues(false);
            });
        }
    }, [opened]);
    useEffect(() => {
        form.reset();
    }, [initialValues]);
    const [loading, setLoading] = useState(false);

    const action = form.values?.[props.config?.idfield] ? "update" : "create";
    const [errors, setErrors] = useState(false);
    const saveRef = useRef<HTMLButtonElement>();
    const Editor = props.editor ?? Modal;
    const formProps: {onSubmit?: () => any} = {};
    const Form = props.form ?? (props.editor ? "div" : "form");
    if (Form === "form") {
        formProps.onSubmit = form.onSubmit((values, event) => {
            if (readOnly) {
                return;
            }
            let data = values;
            /*if (props.dirtyValues === undefined || props.dirtyValues === true) {
                const dirtyValues = (value, key = []) => {
                    if (value && Array.isArray(value)) {
                        const nextValue = [];
                        let set = false;
                        value.forEach((value, nextKey) => {
                            if (form.isDirty([...key, nextKey].join("."))) {
                                const v = dirtyValues(value, [...key, nextKey]);
                                if (v !== undefined) {
                                    set = true;
                                    nextValue[nextKey] = v;
                                }
                            }
                        });
                        return set ? nextValue : undefined;
                    }
                    if (value && typeof value === "object") {
                        const nextValue = {};
                        let set = false;
                        Object.keys(value).forEach((nextKey) => {
                            if (form.isDirty([...key, nextKey].join("."))) {
                                const v = dirtyValues(value[nextKey], [...key, nextKey]);
                                if (v !== undefined) {
                                    set = true;
                                    nextValue[nextKey] = v;
                                }
                            }
                        });
                        return set ? nextValue : undefined;
                    }
                    if (form.isDirty(key.join("."))) {
                        return value;
                    }
                    return undefined;
                }
                data = dirtyValues(values);
            }*/
            if (props.valuesParser) {
                data = JSON.parse(JSON.stringify(props.valuesParser(data)));
            }
            //console.log(data);

            setLoading(true);
            let args;
            args = {
                action: props.config.componentName + "." + action,
                [props.config.idfield]: values[props.config.idfield],
                view,
                data
            };
            let resolved;
            if (props?.actions?.[action]) {
                resolved = Promise.resolve(props.actions[action]({
                    props,
                    request: args
                }));
            } else {
                resolved = postRequest("/components", args);
            }
            resolved.then((res) => {
                console.log(res);
                setLoading(false);
                setOpened(false);

                setInitialValues(values);
                form.reset();

                if (props.onSaved) {
                    props.onSaved();
                }
            }).catch((e) => {
                setErrors(e.message);
            });
            event.preventDefault();
            event.stopPropagation();
            return false;
        }, (errors, values, event) => {
            setErrors(true);
            event.preventDefault();
            event.stopPropagation();
            return false;
        })
    }
    return <>
        <Editor
            size="100%"
            title={<Translation base={[props.config.componentName]} id={props.config.componentName.toLowerCase()} />}
            opened={opened}
            onClose={() => {
                if (form.isDirty()) {
                    setDirtyModal(true);
                } else {
                    setOpened(false);
                }
            }}
            overlayProps={{
                blur: 1,
            }}
            withCloseButton={!loading && !loadingValues}
        >
            <Box pos="relative">
                <LoadingOverlay visible={loading || loadingValues} overlayBlur={2} />
                <Form {...formProps}>
                    {props.children ? <PropsProxy
                        fields={fields}
                    >
                        {props.children}
                    </PropsProxy> : <DynamicComponent
                        path={"Forms/" + props.config.componentName}
                        fallback={<DefaultForm {...props} form={form} fields={fields} />}
                        {...props}
                        action={action}
                        form={form}
                        fields={fields}
                    />}
                    <ConfirmClose
                        opened={dirtyModal}
                        setOpened={setDirtyModal}
                        onDiscard={() => {
                            setDirtyModal(false);
                            setLoading(false);
                            setOpened(false);
                        }}
                        onSave={() => {
                            saveRef?.current?.click();
                        }}
                    />
                    <Group mt="xl" position="right">
                        {props.editor ? null : <Button
                            variant="default"
                            onClick={async () => {
                                if (form.isDirty()) {
                                    setDirtyModal(true);
                                } else {
                                    setLoading(false);
                                    setOpened(false);
                                }
                            }}
                            disabled={loading}
                        >
                            <Translation id="close" />
                        </Button>}
                        {form.values?.[props.config?.idfield] && !readOnly && hasActionPrivilege({
                            config: props.config,
                            action: "create",
                            data: JSON.parse(JSON.stringify(form.values))
                        }) ? <Button
                            variant="outline"
                            onClick={() => {
                                form.setFieldValue(props.config.idfield, undefined);
                                if (props.config.componentName === "Project") {
                                    form.setFieldValue("worksheets", []);
                                    form.setFieldValue("state", 0); // Draft/Piszkozat
                                }
                            }}
                            disabled={loading || readOnly}
                        >
                            <Translation id="copy" />
                        </Button> : null}
                        {hasActionPrivilege({
                            config: props.config,
                            action: action,
                            data: JSON.parse(JSON.stringify(form.values))
                        }) ? <Button
                            ref={saveRef}
                            type="submit"
                            disabled={loading || (!form.values?.[props.config?.idfield] ? false : !form.isDirty()) || readOnly}
                        >
                            <Translation id="save" />
                        </Button> : null}
                    </Group>
                </Form>
            </Box>
        </Editor>
        <Modal
            title={<Translation id="error" />}
            opened={errors}
            centered
            onClose={() => setErrors(false)}
        >
            <Text>{typeof errors === "string" ? errors : <Translation id="error_on_form" />}</Text>
            <Group mt="xl" position="right">
                <Button
                    onClick={() => setErrors(false)}
                >
                    <Translation id="ok" />
                </Button>
            </Group>
        </Modal>
        {
            props.buttons === false
                ?
                null
                :
                (
                    (props.exists ? props.exists({...props, form}) : !!props[props.config.idfield]) ?
                        <Button.Group>
                            <Button
                                variant="filled"
                                onClick={() => {
                                    setOpened(true);
                                }}
                            >
                                <IconPencil />
                            </Button>
                            {hasActionPrivilege({
                                config: props.config,
                                action: "remove",
                                data: JSON.parse(JSON.stringify(form.values))
                            }) ? <Confirm
                                {...deleteProps}
                                onAccept={() => {
                                    let resolved;
                                    const action = "remove";
                                    const args = {
                                        action: props.config.componentName + "." + action,
                                        [props.config.idfield]: props[props.config.idfield],
                                    };
                                    if (props?.actions?.[action]) {
                                        resolved = Promise.resolve(props.actions[action]({
                                            request: {
                                                ...args,
                                                data: form.values,
                                            },
                                            props
                                        }));
                                    } else {
                                        resolved = postRequest("/components", args);
                                    }
                                    resolved.then((res) => {
                                        console.log(res);
                                        if (props.onRemoved) {
                                            props.onRemoved();
                                        }
                                    }).catch((e) => {
                                        setErrors(e.message);
                                    });
                                }}
                            >
                                <Button
                                    variant="outline"
                                >
                                    <IconTrash />
                                </Button>
                            </Confirm> : null}
                            {props.actionButtons}
                        </Button.Group> : <Button.Group>
                            {list(props.disabledActions).indexOf("create") === -1 && hasActionPrivilege({
                                config: props.config,
                                action: "create",
                                data: JSON.parse(JSON.stringify(form.values))
                            }) ? <Button
                                variant="filled"
                                onClick={() => {
                                    setOpened(true);
                                }}
                            >
                                <IconPlus />
                            </Button> : null}
                        </Button.Group>
                )
        }
    </>
};

const WithView = (props: EditorPropsType&{session?: {context: any}}) => {
    const [view, setView] = useState(null);
    useEffect(() => {
        let set = true;
        setView(null);
        let view = {};
        sequence(Object.entries(props.config?.fields || {}).map(async ([field, settings]: [string, ComponentConfigFieldType]) => {
            return import(/* webpackMode: "eager" */ "src/components/Editor/Fields/" + settings.connection.type + "/" + settings.datatype).then(({view: fieldView}) => {
                if (fieldView instanceof Function) {
                    return Promise.resolve(fieldView({...props, view, field: field, fieldSettings: settings})).then((_view) => {
                        view = _view;
                        return undefined;
                    });
                }
                return {field, view: fieldView};
            }).catch((reason) => {
                return {field, view: undefined};
            });
        })).then((all) => {
            all = all.filter(Boolean);
            if (set) {
                const newView = all.reduce((all, {field, view}) => {
                    all[field] = view;
                    return all;
                }, view);
                setView(newView);
            }
        });
        return () => {
            set = false;
        }
    }, [Object.keys(props.config?.fields || {}).join(",")]);
    if (!view) return null;
    return <EditorComp {...props} view={view}></EditorComp>;
}

const Editor = connect((state) => {
    return {
        session: state,
    }
})(WithView);

const configEditors = {};

export const EditorByComponent = React.forwardRef<typeof Editor, Omit<EditorPropsType, 'config'>&{component: string}>((props, ref) => {
    const EditorWithConfig = configEditors[props.component] = configEditors[props.component] ?? withComponentConfig(props.component)(Editor);
    return <EditorWithConfig ref={ref} {...props} />;
});

export default Editor;