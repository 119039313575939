import React, {useEffect, useRef} from "react";
import Translation from "../../../Utils/Translation";
import {NumberInput} from "@mantine/core";
import {FieldPropsType} from "../../Editor";
import useReadOnly from "../useReadOnly";
import {getRequest} from "../../../../utils/request/request";
import useIsInViewport from "../../../Utils/useIsInViewport";
import get from "../../../../utils/object/get";

export const view = null;

function parse(str, values) {
    str = str.split(/([\{][\{][^\}]+[\}][\}])/g).map((value) => {
        if (/{{[^}]+}}/g.test(value)) {
            value = value.slice(2, -2);
            return `get(values, '${value}')`;
        }
        return value;
    }).join("");
    return (new Function('values', 'get', `'use strict'; return ${str}`))(values, get);
}

export default (props: FieldPropsType) => {
    const form = props.form;
    const field = props.field;
    let value = form.values?.[field] ?? '';
    const bases = [props.config.componentName + ".field." + props.field, props.config.componentName];
    const fieldReadOnly = useReadOnly(props);
    const fieldSettings = props.config?.fields?.[props.field];
    if (fieldSettings.virtual) {
        value = parse(fieldSettings.virtual, form.values);
        if (isNaN(value)) {
            value = 0;
        }
    }
    if (isNaN(value)) {
        value = '';
    }
    const ref = useRef(null);
    const isInViewport = useIsInViewport(ref);
    useEffect(() => {
        if (props.readOnly) return;
        let c;
        if (fieldSettings.calculated) {
            const r = () => {
                if (!form.values[props.config.idfield]) {
                    const f = new Function("'use strict'; return " + fieldSettings.calculated.calculator)();
                    const newValue = f(form.values);
                    if (newValue !== value) {
                        form.setValues({[field]: newValue});
                        form.resetDirty({[field]: newValue});
                    }
                } else {
                    const r = getRequest("/components", {
                        action: props.config.componentName + ".findOne",
                        [props.config.idfield]: form.values[props.config.idfield],
                        view: fieldSettings.calculated.view,
                    });
                    r.then((values) => {
                        const f = new Function("'use strict'; return " + fieldSettings.calculated.calculator)();
                        const newValue = f(values);
                        if (newValue !== value) {
                            form.setValues({[field]: newValue});
                            form.resetDirty({[field]: newValue});
                        }
                    });
                    return () => {
                        r.abort();
                    }
                }
            }
            r();
            c = setInterval(r, 1000);
        }
        return () => {
            c && clearInterval(c);
        }
    }, [form.values, value, isInViewport, props.readOnly]);
    return <NumberInput
        ref={ref}
        name={field}
        type="number"
        label={<Translation base={bases} id={field} />}
        withAsterisk={props.config?.fields?.[field].required}
        value={value}
        placeholder={Translation({base: bases, id: field + ".placeholder", fallback: ""})}
        onChange={(value) => form.setFieldValue(field, value)}
        min={props.config.fields[props.field]?.min}
        max={props.config.fields[props.field]?.max}
        error={form.errors?.[field]}
        readOnly={fieldReadOnly || props.readOnly}
        disabled={!!fieldSettings.virtual}
        stepHoldDelay={500}
        stepHoldInterval={100}
        radius="md"
    />
}