import React, {Fragment, useCallback, useMemo, useState} from "react";
import Editor from "../Editor/Editor";
import List, {ListPropsType} from "./List";
import hasActionPrivilege from "../../utils/component/hasActionPrivilege";
import {Button, Input, Modal, Tooltip} from "@mantine/core";
import {IconFileTypePdf} from "@tabler/icons-react";
import * as stylex from "../Utils/StyleInjector";
import list from "../../utils/array/list";
import {
    IconInfoSquare
} from '@tabler/icons-react';
import Translation from "../Utils/Translation";
import DynamicComponent from "../Utils/DynamicComponent";
import ComponentConfig from "../Utils/ComponentConfig";

function emptyComment(text: string|null|undefined) {
    return text === undefined || text === null || text.trim().replace(/\s+/g, '').replace(/<p><\/p>/g, '').replace(/<p\/>/g, '') === "";
}

const percentColors = [
    {pct: 0.0, color: {r: 235, g: 204, b: 204}},
    {pct: 0.5, color: {r: 250, g: 242, b: 204}},
    {pct: 1.0, color: {r: 208, g: 233, b: 198}}
];

const getColorForPercentage = function (pct) {
    let i = 1;
    for (; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
            break;
        }
    }
    let lower = percentColors[i - 1];
    let upper = percentColors[i];
    let range = upper.pct - lower.pct;
    let rangePct = (pct - lower.pct) / range;
    let pctLower = 1 - rangePct;
    let pctUpper = rangePct;
    return {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
}

/*const colors = stylex.defineVars({
    primary: {
        default: '#00ff00',
        ":hover": '#ff00ff',
        '@media (min-width: 1250px)': '#ff0000'
    }
})*/

const styles = stylex.create({
    "example-row": {
        backgroundImage: "linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)",
        backgroundSize: "1rem 1rem",
        backgroundColor: "rgba(0,0,0,.03)",
        fontStyle: "italic",
        /*color: colors.primary*/
        /*color: {
            default: '#00ff00',
            ":hover": '#ff00ff',
            '@media (min-width: 1250px)': '#ff0000'
        }*/
    },
    ...[0,100,200,300,400,500,600].reduce((all, state, currentIndex, array) => {
        return {...all, [`state-${state}`]: {
                backgroundColor: {
                    default: 'rgba(' + Object.values(getColorForPercentage(currentIndex / array.length)).join(',') + ',0.8)',
                    ":hover": 'rgb(' + Object.values(getColorForPercentage(currentIndex / array.length)).join(',') + ') !important',
                }
            }}
    }, {})
});

export default stylex.inject(styles)(React.forwardRef<any, Partial<ListPropsType&{[key: string]: any}>>((props, ref) => {
    const header = useMemo(() => {
        return (_props) => {
            return list(props.disabledActions).indexOf("create") === -1 ? <Editor
                {..._props}
                children={null}
                actions={props.actions}
                exists={props.exists}
                valuesParser={props.valuesParser}
                onSaved={() => {
                    if (!props.value) {
                        _props.setState({..._props.state});
                    }
                }}
            /> : null
        }
    }, [list(props.disabledActions).join(","), props.actions, props.exists, props.valuesParser, JSON.stringify(props.value)]);
    const formatter = useMemo(() => {
        return (_props) => {
            return <>
                <Editor
                    {..._props}
                    {...{[_props.config.idfield]: _props?.data?.[_props.config.idfield]}}
                    actions={props.actions}
                    exists={props.exists}
                    valuesParser={props.valuesParser}
                    onSaved={() => {
                        if (!props.value) {
                            _props.setState({..._props.state});
                        }
                    }}
                    onRemoved={() => {
                        if (!props.value) {
                            _props.setState({..._props.state});
                        }
                    }}
                    values={_props.data}
                    children={null}
                    actionButtons={hasActionPrivilege({
                        config: _props.config,
                        action: "pdf",
                        data: _props.data
                    }) ? <Button
                        variant="outline"
                        onClick={() => {
                            window.open(`/components?action=${_props.config.componentName}.pdf&${_props.config.idfield}=${_props?.data?.[_props.config.idfield]}&_=${new Date().getTime()}`, "_blank");
                        }}
                    >
                        <IconFileTypePdf />
                    </Button> : null}
                />
            </>;
        }
    }, [props.actions, props.exists, props.valuesParser, JSON.stringify(props.value)]);
    const Comments = useCallback(function ({data, value}) {
        const [opened, setOpened] = useState(false);
        if (!emptyComment(data?.project?.comment) || !emptyComment(data.comment) || value?.some?.(({text}) => !emptyComment(text))) {
            return <>
                <Modal
                    size="lg"
                    title={<Translation base={[]} id="comments" />}
                    opened={opened}
                    centered
                    overlayProps={{
                        blur: 1,
                    }}
                    onClose={() => setOpened(false)}
                >
                    {!emptyComment(data?.project?.comment) ? <ComponentConfig component="Project">
                        <DynamicComponent
                            session={props.session}
                            path="Editor/Fields/belongsTo/RichText"
                            field="comment"
                            action="create"
                            value={undefined}
                            disabled={true}
                            readOnly={true}
                            form={{
                                values: data.project,
                                errors: {}
                            }}
                        />
                    </ComponentConfig> : null}
                    {value?.map((comment, idx) => {
                        if (emptyComment(comment.text)) return null;
                        return <ComponentConfig key={idx} component="Comment">
                            <DynamicComponent
                                session={props.session}
                                path="Editor/Fields/belongsTo/RichText"
                                field="text"
                                action="create"
                                value={undefined}
                                disabled={true}
                                readOnly={true}
                                label={`${comment?.owner?.lastname} ${comment?.owner?.firstname}`}
                                form={{
                                    values: comment,
                                    errors: {}
                                }}
                            />
                        </ComponentConfig>;
                    })}
                </Modal>
                <Button
                    onClick={() => setOpened(true)}
                >
                    <IconInfoSquare/>
                </Button>
            </>;
        }
        return null;
    }, [props.session]);
    return <Fragment>
        <List
            ref={ref}
            component="Worksheet"
            rowClassName={({element}) => {
                return stylex.props(styles[`state-${element.state}`], element.example && styles['example-row']).className;
            }}
            filter={props.filter ? props.filter : {field: "project.state", value: 300, operator: ">="}}
            view={["project.comment"]}
            fields={{
                "actions": {
                    header,
                    filterable: false,
                    sortable: false,
                    width: 1,
                    formatter
                },
                "pid": {
                    filterable: true,
                    sortable: true,
                    width: 1,
                },
                "example": {
                    filterable: true,
                    sortable: true,
                    width: 1,
                },
                "project": {
                    filterable: true,
                    sortable: true,
                },
                "qty": {
                    filterable: true,
                    sortable: true,
                },
                "deadline": {
                    filterable: true,
                    sortable: true,
                },
                "state": {
                    filterable: true,
                    sortable: true,
                    width: 15,
                },
                "comments": {
                    filterable: false,
                    sortable: false,
                    formatter: Comments
                }
            }}
            {...props}
        />
    </Fragment>;
}));