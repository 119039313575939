import React from "react";
import {Title} from "@mantine/core";
import Translation from "../Utils/Translation";
import WorksheetList from "../List/WorksheetList";
import CheckPrivilege from "../Utils/CheckPrivilege";
export default () => <CheckPrivilege component="Worksheet">
    <Title order={1}>
        <Translation id="worksheets" />
    </Title>
    <WorksheetList listId="worksheets" />
</CheckPrivilege>;