import React, {useEffect, useState} from "react";
import {Button, Group, Modal, Text} from "@mantine/core";
import {postRequest} from "../../utils/request/request";
import PropsProxy from "./PropsProxy";
import Translation from "./Translation";

type PropsType = {
    title?: any,
    body?: any,
    onCancel?: () => void,
    onAccept: () => void,
    disabled?: boolean,
    children?: any,
    button?: any,
    opened?: boolean,
    acceptButton?: any,
    cancelButton?: any
}&{[key: string]: any};

export const deleteProps = {
    title: "Törlés",
    body: "Biztosan törlöd ezt a tételt?",
    acceptButton: "Törlés",
}

export default ({
    title,
    body,
    children,
    onCancel,
    onAccept,
    disabled,
    button,
    acceptButton,
    cancelButton,
    ...rest
}: PropsType) => {
    const [opened, setOpened] = useState(rest.opened ?? false);
    useEffect(() => setOpened(rest.opened ?? false), [rest.opened]);
    const [loading, setLoading] = useState(false);

    let buttonComp;
    if (!children || typeof(children) === "string") {
        const buttonProps = {...rest, disabled};
        buttonComp = <Button
            {...buttonProps}
        >
            {children || button}
        </Button>
    } else {
        buttonComp = children;
    }

    return <>
        <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            centered
            withCloseButton={loading || disabled}
            overlayProps={{
                blur: 1,
            }}
            title={title}
        >
            {body}
            <Group mt="xl" position="right">
                <Button
                    variant="default"
                    onClick={async () => {
                        setLoading(true);
                        if (onCancel) {
                            await onCancel();
                        }
                        setLoading(false);
                        setOpened(false);
                    }}
                    disabled={loading || disabled}
                >
                    {cancelButton ?? <Translation id="cancel" />}
                </Button>
                <Button
                    onClick={async () => {
                        setLoading(true);
                        await onAccept();
                        setLoading(false);
                        setOpened(false);
                    }}
                    disabled={loading || disabled}
                >
                    {acceptButton ?? <Translation id="ok" />}
                </Button>
            </Group>
        </Modal>
        <PropsProxy onClick={() => setOpened(true)}>
            {buttonComp}
        </PropsProxy>
    </>
}