import React from "react";
import {AppShell, MantineProvider, MantineThemeOverride} from '@mantine/core';
import NavbarMinimalColored from "./Navbar/Navbar";
import AppContainer from "./Containers/AppContainer";
import AuthContainer from "./Containers/AuthContainer";
import {BrowserRouter} from "react-router-dom";
import {proxyWithPersist, StateProvider} from "../valtio";

const branaldiTheme: MantineThemeOverride = {
    colorScheme: 'light',
    primaryColor: 'red',
};

export const state = proxyWithPersist<{
    context?: {
        session?: {user?: {[key: string]: any}}
    }
}>({
    name: "state",
    initialState: {
        context: {}
    }
});

export default () => {
    return <StateProvider state={state}>
        <BrowserRouter>
            <MantineProvider withGlobalStyles withNormalizeCSS theme={branaldiTheme}>
                <AuthContainer>
                    <AppShell
                        padding="md"
                        navbar={<NavbarMinimalColored />}
                        styles={(theme) => ({
                            main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
                        })}
                    >
                        <AppContainer />
                    </AppShell>
                </AuthContainer>
            </MantineProvider>
        </BrowserRouter>
    </StateProvider>;
}