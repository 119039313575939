import React, {useEffect, useState} from "react";

export default ({ fallback, path, ...rest }: {fallback?: any, path: string, [key: string]: any }) => {

    const [Component, setComponent] = useState(null);

    useEffect(() => {
        setComponent(null);
        import( /* webpackMode: "eager" */ "src/components/" + path)
            .then(module => {
                setComponent(() => module.default)
            })
            .catch(_ => {
                setComponent(false);
            })
    }, [path]);
    return Component ? <Component {...rest} /> : (Component === false ? fallback : null);
}