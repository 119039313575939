import Select, {SelectPropsType} from "./Select";
import ComponentSelect from "./ComponentSelect";
import React, {useEffect, useState} from "react";
import {getRequest} from "../../../../utils/request/request";
import {UseFormReturnType} from "@mantine/form";
import ComponentConfig from "../../../Utils/ComponentConfig";
import {Grid} from "@mantine/core";
import useReadOnly from "../useReadOnly";

export * from "./ComponentSelect";

export default (props: SelectPropsType) => {
    const form = props.form;
    const field = props.field;
    const fieldSettings = props.config?.fields?.[field];
    const datatype = props.config.fields[props.field].datatype;
    const isList = fieldSettings.connection?.type == "hasMany";
    const [categorySelector, setCategorySelector] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(!isList ? form.values[field]?.category : null);
    const fieldReadOnly = useReadOnly(props);
    useEffect(() => {
        if (fieldReadOnly) {
            if (!isList) {
                setCategorySelector(form.values[field]?.category ? [{category: form.values[field]?.category}] : []);
                setSelectedCategory(form.values[field]?.category ?? null);
            } else {
                setCategorySelector([]);
                setSelectedCategory(null);
            }
            return;
        }
        if (!isList) {
            let combineFilter = fieldSettings.combine ? Object.entries(fieldSettings.combine).reduce((filter, [field, value]) => {
                if (value === "{{value}}") return filter;
                return [
                    ...filter,
                    {field, value}
                ]
            }, []) : [];
            let req = getRequest("/components", {
                option: datatype,
                page: 1,
                itemsperpage: 0,
                view: {category: null},
                groupBy: ["category"],
                filter: combineFilter.length ? combineFilter : undefined,
            });
            req.then(({items}) => {
                setCategorySelector(items);
            })
            return () => {
                req?.abort();
            }
        }
    },[fieldReadOnly, isList, fieldSettings]);
    if (categorySelector.length < 2) {
        return <ComponentSelect {...props} />;
    } else {
        return (
            <Grid>
                <Grid.Col xs={4}>
                    <ComponentConfig component={datatype}>
                        <Select
                            {...props}
                            objectValue={false}
                            clearable={false}
                            options={categorySelector.map(({category}) => ({label: category, value: category}))}
                            form={{
                                values: {
                                    category: selectedCategory
                                },
                                setFieldValue: (_, value) => {
                                    if (selectedCategory !== value) {
                                        form.setFieldValue(field, null);
                                    }
                                    setSelectedCategory(value);
                                }
                            } as UseFormReturnType<any>}
                            action=""
                            field="category"
                        />
                    </ComponentConfig>
                </Grid.Col>
                <Grid.Col xs={8}>
                    <ComponentSelect
                        {...props}
                        filter={selectedCategory ? {field: "category", value: selectedCategory} : undefined}
                    />
                </Grid.Col>
            </Grid>
        )
    }
}