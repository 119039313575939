// based on https://github.com/therealparmesh/object-to-formdata

const isUndefined = value => value === undefined;

const isNull = value => value === null;

const isObject = value => value === Object(value);

const isArray = value => Array.isArray(value);

const isDate = value => value instanceof Date;

const isBlob = value =>
    value &&
    typeof value.size === 'number' &&
    typeof value.type === 'string' &&
    typeof value.slice === 'function';

const isFile = value =>
    isBlob(value) &&
    typeof value.name === 'string' &&
    (typeof value.lastModifiedDate === 'object' ||
        typeof value.lastModified === 'number');

const objectToFormData = (obj, cfg, fd, pre = '') => {
    cfg = cfg || {};
    cfg.indices = isUndefined(cfg.indices) ? false : cfg.indices;
    cfg.nullsAsUndefineds = isUndefined(cfg.nullsAsUndefineds) ? false : cfg.nullsAsUndefineds;
    cfg.emptyArrayAs = isUndefined(cfg.emptyArrayAs) ? false : cfg.emptyArrayAs;
    cfg.booleanToNumber = isUndefined(cfg.booleanToNumber) ? true : cfg.booleanToNumber;
    fd = fd || new FormData();
    let flags = {
        containsFile: false
    };

    if (isUndefined(obj)) {
        return {data: fd, flags};
    } else if (isNull(obj)) {
        if (cfg.nullsAsUndefineds !== true) {
            fd.append(pre, cfg.nullsAsUndefineds || '')
        }
    } else if (isArray(obj)) {
        if (cfg.emptyArrayAs !== true) {
            if (!obj.length) {
                const key = pre + '';

                fd.append(key, cfg.emptyArrayAs || '')
            } else {
                obj.forEach((value, index) => {
                    const key = pre + '[' + (cfg.indices ? index : '') + ']';

                    flags.containsFile = objectToFormData(value, cfg, fd, key).flags.containsFile || flags.containsFile;
                })
            }
        } else {
            if (!obj.length) {
                const key = pre + '[]';

                fd.append(key, '')
            } else {
                obj.forEach((value, index) => {
                    const key = pre + '[' + (cfg.indices ? index : '') + ']';

                    flags.containsFile = objectToFormData(value, cfg, fd, key).flags.containsFile || flags.containsFile;
                })
            }
        }
    } else if (isDate(obj)) {
        fd.append(pre, obj.toISOString())
    } else if (isObject(obj) && !isFile(obj) && !isBlob(obj)) {
        Object.keys(obj).forEach(prop => {
            const value = obj[prop];

            if (isArray(value)) {
                while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
                    prop = prop.substring(0, prop.length - 2)
                }
            }

            const key = pre ? pre + '[' + prop + ']' : prop;

            flags.containsFile = objectToFormData(value, cfg, fd, key).flags.containsFile || flags.containsFile;
        })
    } else {
        if (cfg.booleanToNumber && typeof obj === "boolean") {
            obj = obj ? 1 : 0;
        }
        if (isFile(obj)) {
            objectToFormData({...obj}, cfg, fd, pre);
            fd.append(pre, obj, obj.name);
        } else {
            fd.append(pre, obj);
        }
        if (isFile(obj) || isBlob(obj)) {
            flags.containsFile = true;
        }
    }

    return {data: fd, flags};
}

export default objectToFormData