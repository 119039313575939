import React, {useContext, useEffect, useState} from "react";
import {subscribe} from "valtio";

const StateContext = React.createContext(undefined);

const StateProvider = ({state, children}) => {
    const [contextState, setState] = useState(state);
    useEffect(() => {
        return subscribe(state, () => {
            setState(state);
        });
    });
    const ContextProvider = StateContext.Provider;
    return <ContextProvider value={contextState}>
        {children}
    </ContextProvider>
}

const useStateContext = () => {
    const state = useContext(StateContext);
    // if `undefined`, throw an error
    if (state === undefined) {
        throw new Error("valtio StateContext was used outside of its Provider!");
    }
    return state;
}

export {
    useStateContext,
    StateContext,
    StateProvider
};