import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FieldPropsType} from "../../Editor";
import useReadOnly from "../useReadOnly";
import {get} from "../../../../utils/object";
import Translation from "../../../Utils/Translation";
import {Input} from "@mantine/core";
import {ComponentConfigFieldType, ComponentConfigType} from "../../../../utils/component/ComponentConfig";
import {getRequest, postRequest} from "../../../../utils/request/request";
import DynamicComponent from "../../../Utils/DynamicComponent";
import ComponentConfig from "../../../Utils/ComponentConfig";
import debounce from "../../../../utils/debounce";

export const view = ({view, config: parentConfig, fieldSettings}: {view: Record<string, any>, config: ComponentConfigType, fieldSettings: ComponentConfigFieldType}) => {
    //const foreignKey = fieldSettings.connection.foreignKey ?? (snakeCase(parentConfig.componentName) + '_id');
    const localKey = fieldSettings.connection.localKey ?? parentConfig.idfield;
    view[localKey] = view[localKey] ?? null;
    return view;
};

function emptyComment(text: string|null|undefined) {
    return text === undefined || text === null || text.trim().replace(/\s+/g, '').replace(/<p><\/p>/g, '').replace(/<p\/>/g, '') === "";
}

const requests = {}

export default (props: FieldPropsType) => {
    const form = props.form;
    const field = props.field;
    const fieldName = field.split(".");
    const currentField = fieldName.shift();
    const fieldSettings = props.config?.fields?.[currentField];
    const value = !form.values[props.config.idfield] ? (get(form.values, field) ?? []) : undefined;
    const fieldReadOnly = useReadOnly(props);
    const foreignIdKey = fieldSettings.connection.foreignKey ?? 'component_id';
    const foreignComponentKey = fieldSettings.connection.foreignKey ?? 'component_type';
    const localKey = fieldSettings.connection.localKey ?? props.config.idfield;
    const valuesParser = useCallback((data) => {
        data[foreignIdKey] = form.values[localKey];
        data[foreignComponentKey] = props.config.componentName;
        return data;
    }, [foreignIdKey]);
    const filter = useMemo(() => {
        return form.values[localKey] ? [
            {field: foreignIdKey, value: form.values[localKey]},
            {field: foreignComponentKey, value: props.config.componentName}
        ] : undefined;
    }, [localKey, foreignIdKey, foreignComponentKey, form.values[localKey]]);
    const exists = useMemo(() => {
        return !!form.values[props.config.idfield];
    }, [form.values[props.config.idfield]]);
    const [comments, setComments] = useState(!exists ? (() => {
        let items = value ?? [];
        if (!items?.find((comment) => props.session.context?.user?.id + "" === "" + comment?.owner?.id)) {
            items.push({text: '', owner: props.session.context?.user});
        }
        return items;
    })() : null);
    useEffect(() => {
        if (!exists) {
            let items = value ?? [];
            if (!items?.find((comment) => props.session.context?.user?.id + "" === "" + comment?.owner?.id)) {
                items.push({text: '', owner: props.session.context?.user});
            }
            setComments(items);
        } else {
            setComments(null);
            const request = {
                itemsperpage: 0,
                filter: filter,
                option: "Comment",
                view: {
                    [foreignIdKey]: null,
                    [foreignComponentKey]: null,
                    text: null,
                    owner: null
                }
            }
            const req = getRequest("/components", request);
            req.then(({items}) => {
                if (!items.find((comment) => props.session.context?.user?.id + "" === "" + comment?.owner?.id)) {
                    items.push({text: '', owner: props.session.context?.user});
                }
                setComments(items);
            });
            return () => {
                req.abort();
            }
        }
    }, [filter, exists, value]);
    const bases = ["Comment.field." + props.field, "Comment"];
    useEffect(() => {
        return () => {
            const data = valuesParser({});
            delete requests[`${data[foreignComponentKey]}-${data[foreignIdKey]}`];
        }
    }, [])
    const debounceSave = useCallback(debounce((exists, idx, comments, comment, value) => {
        const newComments = [...(comments ?? [])];
        newComments[idx] = {...comment};
        const oldText = newComments[idx].text;
        newComments[idx].text = value;
        if (exists) {
            const data = valuesParser({
                ...newComments[idx]
            });
            const key = `${data[foreignComponentKey]}-${data[foreignIdKey]}`;
            const p = requests[key] ? requests[key] : Promise.resolve(newComments[idx]);
            p.then((comment) => {
                requests[key] = postRequest("/components", {
                    action: "Comment." + (comment.id ? "update" : "create"),
                    id: comment.id,
                    view: {
                        [foreignIdKey]: null,
                        [foreignComponentKey]: null,
                        text: null,
                        owner: null
                    },
                    data
                }).then((comment) => {
                    newComments[idx] = data;
                    newComments[idx].text = oldText;
                    setComments(newComments);
                    return comment;
                });
            })
        } else {
            form.setFieldValue(field, newComments);
        }
    }, 300), []);
    const saveChanges = useCallback(function (exists, idx, comments, comment, value) {
        debounceSave(exists, idx, comments, comment, value);
    }.bind({}), []);
    return <>
        <Input.Wrapper
            label={<Translation base={[props.config.componentName + ".field." + currentField, props.config.componentName]} id={currentField} />}
            withAsterisk={fieldSettings.required}
            error={form.errors?.[field]}
        >
            {null}
        </Input.Wrapper>
        {comments?.sort((comment) => {
            return comment.id && props.session.context?.user?.id + "" === "" + comment?.owner?.id ? -1 : 0;
        }).map((comment, idx) => {
            return <div key={idx}>
                {!comment.id || (comment.id && props.session.context?.user?.id + "" === "" + comment?.owner?.id) ? <ComponentConfig component="Comment">
                    <DynamicComponent
                        session={props.session}
                        path="Editor/Fields/belongsTo/RichText"
                        field="text"
                        action="create"
                        value={undefined}
                        form={{
                            values: comment,
                            errors: {},
                            setFieldValue: (field, value) => {
                                saveChanges(exists, idx, comments, comment, value);
                            }
                        }}
                    />
                </ComponentConfig> : (!emptyComment(comment?.text) ? <ComponentConfig component="Comment">
                    <DynamicComponent
                        session={props.session}
                        path="Editor/Fields/belongsTo/RichText"
                        field="text"
                        action="create"
                        value={undefined}
                        disabled={true}
                        readOnly={true}
                        label={`${comment?.owner?.lastname} ${comment?.owner?.firstname}`}
                        form={{
                            values: comment,
                            errors: {}
                        }}
                    />
                </ComponentConfig> : null)}
            </div>
        })}
    </>;
};