import React from "react";
import {Group, Radio} from "@mantine/core";
import {FieldPropsType} from "../../Editor";
import Translation from "../../../Utils/Translation";
import useReadOnly from "../useReadOnly";

export const view = null;

export default (props: FieldPropsType) => {
    const form = props.form;
    const field = props.field;
    const fieldSettings = props.config?.fields?.[field];
    const fieldReadOnly = useReadOnly(props);
    const value = ["1",true,1].includes(form.values?.[field]) ? "1" : (["0",false,0].includes(form.values?.[field]) ? "0" : "undefined");
    return <Radio.Group
        name={field}
        label={<Translation base={[props.config.componentName + ".field." + props.field, props.config.componentName]} id={field} />}
        withAsterisk={props.config?.fields?.[field].required}
        value={value}
        error={form.errors?.[field] === true ? <Translation id="please_fill" /> : form.errors?.[field]}
        onChange={(value) => {
            if (fieldReadOnly || props.readOnly) return;
            form.setFieldValue(field, parseInt(value));
        }}
    >
        <Group mt="xs">
            <Radio
                value="1"
                checked={value === "1"}
                label={<Translation id="yes" />}
                readOnly={fieldReadOnly || props.readOnly}
            />
            <Radio
                value="0"
                checked={value === "0"}
                label={<Translation id="no" />}
                readOnly={fieldReadOnly || props.readOnly}
            />
        </Group>
    </Radio.Group>
}