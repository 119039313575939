export default (list = null, ...items) => {
    if (!Array.isArray(list)) {
        if (list !== undefined && list !== null) {
            list = [list];
        } else {
            list = [];
        }
    } else {
        list = [...list];
    }
    if (items.length) {
        return list.concat(items);
    }
    return list;
}