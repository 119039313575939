import {Input} from "@mantine/core";
import React, {useEffect} from "react";
import {FilterPropsType} from "../List";
import {useDebouncedState} from "@mantine/hooks";
import {uniqueFilter} from "../../../utils/array";

export default ({field, settings, state, setState, config}: FilterPropsType) => {
    const [filterValue, setFilterValue] = useDebouncedState(state.headerFilters?.[field]?._value ?? '', 300);
    useEffect(() => {
        const newHeaderFilters = {...(state.headerFilters || {})};
        let changed = false;
        if (filterValue.length) {
            newHeaderFilters[field] = {
                _value: filterValue,
                filters: Object.values(filterValue.replace(/[^0-9,\-<>]/g, '').split(",").reduce((all, value) => {
                    if (value === '') return all;
                    if (value.indexOf("<") !== -1 || value.indexOf(">") !== -1) {
                        value = value = value.replace(/[<>]/g, "-");
                    }
                    if (value.indexOf("-") !== -1) {
                        const [from, to] = value.split("-");
                        if (from !== '' && to !== '') {
                            all.between = all.between ?? [];
                            all.between.push({
                                field,
                                operator: "between",
                                value: [from, to]
                            });
                        } else if (from !== '') {
                            all.higher = all.higher ?? [];
                            all.higher.push({
                                field,
                                operator: ">",
                                value: from
                            });
                        } else if (to !== '') {
                            all.lower = all.lower ?? [];
                            all.lower.push({
                                field,
                                operator: "<",
                                value: to
                            });
                        }
                        return all;
                    }
                    all.other = {
                        field,
                        operator: "in",
                        value: [...(all.other?.value ?? []), value].filter(uniqueFilter)
                    }
                    return all;
                }, {})).reduce((all: any[], next) => ([...all, ...(Array.isArray(next) ? next : [next])]), []),
                operator: "OR"
            };
            changed = true;
        } else if (newHeaderFilters.hasOwnProperty(field)) {
            delete newHeaderFilters[field];
            changed = true;
        }
        if (changed) {
            setState({...state, headerFilters: newHeaderFilters});
        }
    }, [filterValue]);
    return <Input
        defaultValue={filterValue}
        onChange={(event) => setFilterValue(event.currentTarget.value)}
    />
}