import React from "react";
import {Title} from "@mantine/core";
import Translation from "../Utils/Translation";
import TranslationList from "../List/TranslationList";
import CheckPrivilege from "../Utils/CheckPrivilege";

export default () => <CheckPrivilege component="Translation">
    <Title order={1}>
        <Translation id="translations" />
    </Title>
    <TranslationList listId="translations" />
</CheckPrivilege>;