import React, {useCallback, useMemo} from "react";
import {FieldPropsType} from "../../Editor";
import useReadOnly from "../useReadOnly";
import {get} from "../../../../utils/object";
import Translation from "../../../Utils/Translation";
import {Input} from "@mantine/core";
import WorksheetList from "../../../List/WorksheetList";
import {ComponentConfigFieldType, ComponentConfigType} from "../../../../utils/component/ComponentConfig";
import {snakeCase} from "../../../../utils/string/change-case";
import {getRowIndex} from "../../../List/List";

export const view = ({view, config: parentConfig, fieldSettings}: {view: Record<string, any>, config: ComponentConfigType, fieldSettings: ComponentConfigFieldType}) => {
    //const foreignKey = fieldSettings.connection.foreignKey ?? (snakeCase(parentConfig.componentName) + '_id');
    const localKey = fieldSettings.connection.localKey ?? parentConfig.idfield;
    view[localKey] = view[localKey] ?? null;
    return view;
};

export default (props: FieldPropsType) => {
    const form = props.form;
    const field = props.field;
    const fieldName = field.split(".");
    const currentField = fieldName.shift();
    const fieldSettings = props.config?.fields?.[currentField];
    const value = !form.values[props.config.idfield] ? (get(form.values, field) ?? []) : undefined;
    const fieldReadOnly = useReadOnly(props);
    const actions = useMemo(() =>
        (!form.values[props.config.idfield] ? {
            update: ({request: {data}, props: {values}}) => {
                const newValue = [...(value ?? [])];
                const index = getRowIndex(values) ?? -1;
                if (index !== -1) {
                    newValue[index] = {...newValue[index], ...data};
                } else {
                    newValue.push(data);
                }
                form.setFieldValue(field, newValue);
            },
            create: ({request: {data}, props: {values}}) => {
                const newValue = [...(value ?? [])];
                const index = getRowIndex(values) ?? -1;
                if (index !== -1) {
                    newValue[index] = {...newValue[index], ...data};
                } else {
                    newValue.push(data);
                }
                form.setFieldValue(field, newValue);
            },
            remove: ({request: {data}, props: {values}}) => {
                const newValue = [...(value ?? [])];
                const index = getRowIndex(values) ?? -1;
                if (index !== -1) {
                    newValue.splice(index, 1);
                }
                form.setFieldValue(field, newValue);
            },
            findOne: ({props: {values: data}}) => {
                return data;
            }
        } : undefined), [form.values[props.config.idfield], JSON.stringify(value)]);
    const foreignKey = fieldSettings.connection.foreignKey ?? (snakeCase(props.config.componentName) + '_id');
    const localKey = fieldSettings.connection.localKey ?? props.config.idfield;
    const valuesParser = useCallback((data) => {
        data[foreignKey] = form.values[localKey];
        return data;
    }, [foreignKey]);
    const filter = useMemo(() => {
        return form.values[localKey] ? [
            {field: foreignKey, value: form.values[localKey]}
        ] : undefined;
    }, [localKey, foreignKey, form.values[localKey]]);
    const exists = useMemo(() => {
        return !form.values[props.config.idfield] ? ({values}) => getRowIndex(values) !== undefined : undefined;
    }, [form.values[props.config.idfield]]);
    return <>
        <Input.Wrapper
            label={<Translation base={[props.config.componentName + ".field." + currentField, props.config.componentName]} id={currentField} />}
            withAsterisk={fieldSettings.required}
            error={form.errors?.[field]}
        >
            {null}
        </Input.Wrapper>
        <WorksheetList
            value={value}
            valuesParser={valuesParser}
            readOnly={fieldReadOnly}
            exists={exists}
            filter={filter}
            actions={actions}
        />
    </>;
};