import React from "react";
import Translation from "../../../Utils/Translation";
import {Textarea} from "@mantine/core";
import {FieldPropsType} from "../../Editor";
import useReadOnly from "../useReadOnly";

export const view = null;

export default (props: FieldPropsType) => {
    const form = props.form;
    const field = props.field;
    const bases = [props.config.componentName + ".field." + props.field, props.config.componentName];
    const fieldReadOnly = useReadOnly(props);
    return <Textarea
        name={field}
        label={<Translation base={bases} id={field} />}
        withAsterisk={props.config?.fields?.[field].required}
        value={form.values?.[field] ?? ''}
        placeholder={Translation({base: bases, id: field + ".placeholder", fallback: ""})}
        onChange={(event) => form.setFieldValue(field, event.currentTarget.value)}
        error={form.errors?.[field]}
        readOnly={fieldReadOnly || props.readOnly}
        radius="md"
    />
}