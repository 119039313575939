const getLabelField = ({config}: {config: any}) => {
    let labelField = config.labelField;
    if (!labelField) {
        if (config.fields?.label) {
            labelField = "label";
        } else if (config.fields?.name) {
            labelField = "name";
        } else {
            if (config.targetConfig) {
                return getLabelField({config: config.targetConfig});
            }
        }
    }
    return labelField
}

export default getLabelField;