import React from "react";
import Translation from "../../../Utils/Translation";
import {TextInput} from "@mantine/core";
import {FieldPropsType} from "../../Editor";

export const view = null;
export default (props: FieldPropsType) => {
    const form = props.form;
    const field = props.field;
    return <TextInput
        label={<Translation base={[props.config.componentName + ".field." + props.field, props.config.componentName]} id={field} />}
        value={form.values?.[field] ?? ''}
        onChange={(event) => form.setFieldValue(field, event.currentTarget.value)}
        error={form.errors?.[field]}
        readOnly={true}
        radius="md"
    />
}