import React from "react";
import {FormPropsType} from "../Editor/Editor";
import {Grid} from "@mantine/core";

export default (props: FormPropsType) => {
    return <>
        <Grid>
            <Grid.Col xs={2}>{props.fields.id}</Grid.Col>
            <Grid.Col xs={8}>{props.fields.label}</Grid.Col>
            <Grid.Col xs={2}>{props.fields.qty}</Grid.Col>
            {/*<Grid.Col xs={6}>{props.fields.square_meter}</Grid.Col>*/}
            <Grid.Col xs={12}>{props.fields.file_location}</Grid.Col>
            <Grid.Col xs={4}>{props.fields.partner}</Grid.Col>
            <Grid.Col xs={4}>{props.fields.customer}</Grid.Col>
            <Grid.Col xs={4}>{props.fields.agent}</Grid.Col>
            <Grid.Col xs={6}>{props.fields.orderdate}</Grid.Col>
            <Grid.Col xs={6}>{props.fields.deadline}</Grid.Col>
            <Grid.Col xs={12}>{props.fields.shipping}</Grid.Col>
            <Grid.Col xs={12}>{props.fields.shipping_comment}</Grid.Col>
            <Grid.Col xs={12}>{props.fields.production_size}</Grid.Col>
            <Grid.Col xs={12}>{props.fields.elements}</Grid.Col>
            <Grid.Col xs={12}>{props.fields.production_comment}</Grid.Col>
            <Grid.Col xs={3}>{props.fields.mounting}</Grid.Col>
            {props.fields.mounting_double_sized && <Grid.Col xs={3}>{props.fields.mounting_double_sized}</Grid.Col>}
            <Grid.Col xs={12} />
            <Grid.Col xs={6}>{props.fields.post_production}</Grid.Col>
            <Grid.Col xs={6}>{props.fields.packing}</Grid.Col>
            <Grid.Col xs={12}>{props.fields.parts}</Grid.Col>
            <Grid.Col xs={12}>{props.fields.comment}</Grid.Col>
            <Grid.Col xs={12}>{props.fields.worksheets}</Grid.Col>
            <Grid.Col xs={12}>{props.fields.preview}</Grid.Col>
            <Grid.Col xs={12}>{props.fields.price_gross}</Grid.Col>
            <Grid.Col xs={12}>{props.fields.price_net_total}</Grid.Col>
            <Grid.Col xs={12}>{props.fields.state}</Grid.Col>
        </Grid>
    </>
}