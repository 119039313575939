import React from "react";
import {FormPropsType} from "../Editor/Editor";
import {Container, Grid} from "@mantine/core";

export default (props: FormPropsType) => {
    return <>
        <Grid>
            <Grid.Col xs={12}>{props.fields.optionname}</Grid.Col>
            <Grid.Col xs={4}>{props.fields.value}</Grid.Col>
            <Grid.Col xs={4}>{props.fields.category}</Grid.Col>
            <Grid.Col xs={4}>{props.fields.label}</Grid.Col>
        </Grid>
    </>
}