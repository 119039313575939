import React, {useEffect} from "react";
import {FilterPropsType} from "../List";
import {useDebouncedState} from "@mantine/hooks";
import {Input} from "@mantine/core";
import ComponentConfig from "../../Utils/ComponentConfig";
import getSearchFields from "../../../utils/component/getSearchFields";
import {minimumLengthOfSearch} from "../../../config";

const Filter = ({field, settings, state, setState, config, fieldConfig}: FilterPropsType&{fieldConfig?: any}) => {
    const searchFields = getSearchFields({config: fieldConfig}) ?? [];
    const [search, setSearch] = useDebouncedState(state.headerFilters?.[field]?._value ?? '', 300);
    useEffect(() => {
        const newHeaderFilters = {...(state.headerFilters || {})};
        if (search.length >= minimumLengthOfSearch) {
            newHeaderFilters[field] = {
                _value: search,
                filters: searchFields.reduce((all, subField) => {
                    return [...all, ...search.split(/\s+/).map((search) => ({
                        field: field + "." + subField,
                        value: `%${search}%`,
                        operator: "LIKE"
                    }))];
                }, []),
                operator: "OR"
            }
            setState({...state, headerFilters: newHeaderFilters});
        } else if (newHeaderFilters[field]) {
            delete newHeaderFilters[field];
            setState({...state, headerFilters: newHeaderFilters});
        }
    }, [search]);
    return <Input
        defaultValue={search}
        onChange={(event) => setSearch(event.currentTarget.value)}
    />
}

export default (props: FilterPropsType) => {
    if (!props.config?.fields?.[props.field]?.datatype) return null;
    return <ComponentConfig
        component={props.config?.fields?.[props.field]?.datatype}
        as="fieldConfig"
    >
        <Filter {...props} />
    </ComponentConfig>
}