import Select, {SelectPropsType} from "./Select";
import React, {useMemo} from "react";

export const view = null;

export default (props: SelectPropsType) => {
    const field = props.field;
    const fieldSettings = props.config?.fields?.[field];
    const states = useMemo(() => Object.keys(fieldSettings.states).map((k) => ({value: Number(k), label: k})), [JSON.stringify(fieldSettings.states)]);
    return <Select
        {...props}
        options={states}
    />
}