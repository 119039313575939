import React from "react";
import Translation from "../../../Utils/Translation";
import {PasswordInput} from "@mantine/core";
import {FieldPropsType} from "../../Editor";
import useReadOnly from "../useReadOnly";

export default (props: FieldPropsType) => {
    const form = props.form;
    const field = props.field;
    const fieldReadOnly = useReadOnly(props);
    return <>
        <PasswordInput
            name={field}
            label={<Translation base={[props.config.componentName + ".field." + props.field, props.config.componentName]} id={field} />}
            withAsterisk={props.config?.fields?.[field].required}
            value={form.values?.[field] ?? ''}
            onChange={(event) => form.setFieldValue(field, event.currentTarget.value)}
            error={form.errors?.[field]}
            readOnly={fieldReadOnly || props.readOnly}
            radius="md"
        />
    </>;
}