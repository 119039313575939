import React, {Fragment} from "react";
import {EditorByComponent, FormPropsType} from "../Editor/Editor";
import {Grid} from "@mantine/core";
import {connect} from "../../valtio";

export default connect((state) => {
    return {
        session: state,
    }
})((props: FormPropsType&{session: { context: any }}) => {
    return <>
        <Grid>
            <Grid.Col xs={1}>{props.fields.pid}</Grid.Col>
            <Grid.Col xs={2}>{props.fields.example}</Grid.Col>
            <Grid.Col xs={2}>{props.fields.qty}</Grid.Col>
            <Grid.Col xs={2}>{props.fields.deadline}</Grid.Col>
            <Grid.Col xs={2}>{props.fields.state}</Grid.Col>
            <Grid.Col xs={12}>{props.fields.state_comment}</Grid.Col>
            <Grid.Col xs={12}>{props.fields.comments}</Grid.Col>
            {props.session?.context?.user?.group === "worker" ? <Grid.Col xs={12}>
                <EditorByComponent
                    component="Project"
                    editor={({children}) => <Fragment>{children}</Fragment>}
                    readOnly={true}
                    values={props.form.values.project}
                />
            </Grid.Col> : null}
        </Grid>
    </>
});