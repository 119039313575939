import React, {useEffect, useState} from 'react';
import {
    Navbar,
    Center,
    Tooltip,
    UnstyledButton,
    createStyles,
    Stack,
    rem,
    Text,
    Group,
    Button,
    Modal
} from '@mantine/core';
import {
    IconHome2,
    IconCalendarStats,
    IconUser,
    IconAdjustments,
    IconSettings,
    IconLogout, IconBox, IconLanguage,
} from '@tabler/icons-react';
import {NavLink, useNavigate} from "react-router-dom";
import Translation from "../Utils/Translation";
import {connect} from "../../valtio";
import {getRequest, postRequest} from "../../utils/request/request";
import pluralize from "pluralize";
import {pascalCase} from "../../utils/string/change-case";
import CheckPrivilege from "../Utils/CheckPrivilege";

const useStyles = createStyles((theme) => ({
    link: {
        width: rem(50),
        height: rem(50),
        borderRadius: theme.radius.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.white,
        opacity: 0.85,

        '&:hover': {
            opacity: 1,
            backgroundColor: theme.fn.lighten(
                theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
                0.1
            ),
        },
    },

    active: {
        opacity: 1,
        '&, &:hover': {
            backgroundColor: theme.fn.lighten(
                theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
                0.15
            ),
        },
    },
}));

interface NavbarLinkProps {
    icon: React.FC<any>;
    label: string;
    active?: boolean;
    to?: string;
}

function NavbarLink({ icon: Icon, label, active, to }: NavbarLinkProps) {
    const { classes, cx } = useStyles();
    return (
        <Tooltip label={<Translation base="menu" id={label} />} position="right" transitionProps={{ duration: 0 }}>
            <NavLink to={to}>
                {({ isActive, isPending }) => (
                    <UnstyledButton className={cx(classes.link, { [classes.active]: isActive })}>
                        <Icon size="1.2rem" stroke={1.5} />
                    </UnstyledButton>
                )}
            </NavLink>
        </Tooltip>
    );
}

const Logout = connect((state) => {
    return {
        state: state,
    }
})(({state}: {state?: any}) => {
    const { classes, cx } = useStyles();
    const [opened, setOpened] = useState(false);
    return (
        <>
            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                centered
                overlayProps={{
                    blur: 1,
                }}
            >
                <Text>Biztos kilépsz?</Text>
                <Group mt="xl" position="right">
                    <Button variant="default" onClick={() => setOpened(false)}>
                        <Translation id="cancel" />
                    </Button>
                    <Button onClick={() => {
                        postRequest("/logout").then((res) => {
                            state.context = res.context;
                        });
                    }}>
                        <Translation id="logout" />
                    </Button>
                </Group>
            </Modal>
            <Tooltip label={<Translation base="menu" id="logout" />} position="right" transitionProps={{ duration: 0 }}>
                <a onClick={() => setOpened(true)}>
                    <UnstyledButton className={cx(classes.link)}>
                        <IconLogout size="1.2rem" stroke={1.5} />
                    </UnstyledButton>
                </a>
            </Tooltip>
        </>
    );
});

const mockdata = [
    { icon: IconHome2, label: 'dashboard', to: "" },
    { icon: IconBox, label: 'projects', to: "projects" },
    { icon: IconCalendarStats, label: 'worksheets', to: "worksheets" },
    { icon: IconUser, label: 'users', to: "users" },
    { icon: IconAdjustments, label: 'options', to: "options" },
    { icon: IconLanguage, label: 'translations', to: "translations" },
    //{ icon: IconSettings, label: 'settings', to: "settings" },
];



export default function NavbarMinimalColored() {

    const links = mockdata.map((link, index) => (
        <CheckPrivilege key={index} component={pascalCase(pluralize.singular(link.to))}>
            <NavbarLink
                {...link}
                key={link.label}
            />
        </CheckPrivilege>
    ));

    return (
        <Navbar
            height='100%'
            width={{ base: 80 }}
            p="md"
            sx={(theme) => ({
                backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
            })}
        >
            <Center>
                <img src="public/static/branaldi-logo-icon.png" alt="Branaldi" width="100%" />
            </Center>
            <Navbar.Section grow mt={50}>
                <Stack justify="center" spacing={0}>
                    {links}
                </Stack>
            </Navbar.Section>
            <Navbar.Section>
                <Stack justify="center" spacing={0}>
                    <Logout />
                </Stack>
            </Navbar.Section>
        </Navbar>
    );
}