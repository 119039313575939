import React, {useRef} from "react";
import {Title} from "@mantine/core";
import Translation from "../Utils/Translation";
import WorksheetList from "../List/WorksheetList";
import CheckPrivilege from "../Utils/CheckPrivilege";

export default () => {
    const w0 = useRef(null);
    const w100 = useRef(null);
    const w200 = useRef(null);
    return <CheckPrivilege component="Worksheet">
        <Title order={1}>
            <Translation id="300" base={["worksheet.field.state"]} />
        </Title>
        <WorksheetList
            ref={w0}
            filter={[{field: "state", value: 300},{field: "project.state", value: [300,400,500], operator: "in"}]}
            order={{field: "project.score", direction: "DESC"}}
            itemsperpage={0}
            disabledActions={["create"]}
            onStateChanged={() => {
                w100.current?.reload?.();
                w200.current?.reload?.();
            }}
            listId="Worksheets_0"
        />
        <Title order={1}>
            <Translation id="400" base={["worksheet.field.state"]} />
        </Title>
        <WorksheetList
            ref={w100}
            filter={[{field: "state", value: 400},{field: "project.state", value: [300,400,500], operator: "in"}]}
            order={{field: "project.score", direction: "DESC"}}
            itemsperpage={0}
            disabledActions={["create"]}
            onStateChanged={() => {
                w0.current?.reload?.();
                w200.current?.reload?.();
            }}
            listId="Worksheets_100"
        />
        <Title order={1}>
            <Translation id="500" base={["worksheet.field.state"]} />
        </Title>
        <WorksheetList
            ref={w200}
            filter={[{field: "state", value: 500},{field: "project.state", value: [300,400,500], operator: "in"}]}
            order={{field: "project.score", direction: "DESC"}}
            itemsperpage={0}
            disabledActions={["create"]}
            onStateChanged={() => {
                w0.current?.reload?.();
                w100.current?.reload?.();
            }}
            listId="Worksheets_200"
        />
    </CheckPrivilege>;
}