/**
 * https://github.com/dondevi/promise-abortable
 * Custom AbortController
 *
 * @return {Object} abortController
 */

export type AbortControllerType = {
    signal: AbortSignalType,
    abort: (reason?: any) => void
}

export default function getAbortController (): AbortControllerType {
    const abortSignal = getAbortSignal();
    const abort = function (reason: any) {
        if (abortSignal.aborted) { return; }
        abortSignal.aborted = true;
        abortSignal.dispatchEvent(reason);  // Different from AbortSignal
    };
    return <AbortControllerType>{
        signal: abortSignal,
        abort: abort
    };
}

export type AbortSignalType = {
    aborted: boolean,
    onAbort: null|((reason?: any) => void),
    dispatchEvent: Function
}

/**
 * Custom AbortSignal
 *
 * @return {Object} abortSignal
 */
function getAbortSignal (): AbortSignalType {
    const abortSignal: AbortSignalType = {
        aborted: false,
        onAbort: null,
        dispatchEvent: function (event: any) {
            if ("function" === typeof abortSignal.onAbort) {
                abortSignal.onAbort(event);
            }
        }
    };
    return abortSignal;
}