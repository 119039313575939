import get from "./object/get";
import {state} from "../components/App";

const filterData = ({filter, data}: {filter: any, data: any}) => {
    const components = {
        Context: JSON.parse(JSON.stringify(state.context))
    };
    if (Array.isArray(filter)) {
        for (const subFilter of filter) {
            if (!filterData({filter: subFilter, data})) {
                return false;
            }
        }
        return true;
    } else if (filter?.filters) {
        const operator = filter.operator || "AND";
        if (operator.trim().toUpperCase() === "OR") {
            for (const subFilter of filter.filters) {
                if (filterData({filter: subFilter, data})) {
                    return true;
                }
            }
            return false;
        } else if (operator.trim().toUpperCase() === "AND") {
            for (const subFilter of filter.filters) {
                if (!filterData({filter: subFilter, data})) {
                    return false;
                }
            }
            return true;
        }
    } else {
        let values = [];
        Object.entries(filter).forEach(([key, value]) => {
            if (key === "componentValue") {
                values.push(get(components, value));
            }
            if (key == "field") {
                values.push(get(data, value));
            }
            if (key == "value") {
                values.push(value);
            }
            if (key === "fields" && Array.isArray(value)) {
                value.forEach((value) => {
                    values.push(get(data, value));
                });
            }
        });
        const operator = (filter.operator || "=").toLowerCase();
        switch (operator) {
            case "between":
                return values[1][0] <= values[0] && values[1][1] >= values[0];
            case "not isnull":
                return values[0] !== null && values[0] !== undefined;
            case "isnull":
                return values[0] === null || values[0] === undefined;
            case "!~":
            case "~":
            case "!=":
            case "=":
            case "==":
            case "<":
            case ">":
            case "<=":
            case ">=":
            case "===":
            case "not in":
            case "in":
            case "includes":
            case "not includes":
                return operation(values[0], values[1], operator);
            case "like":
                // https://stackoverflow.com/a/37511463
                let fieldValue = String(values[0]);
                fieldValue = fieldValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                let value = String(values[1]);
                value = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const matchmode = filter.matchmode || "any";
                switch (matchmode) {
                    case "start":
                        return String(fieldValue).indexOf(value) === 0;
                    case "end":
                        const s = String(fieldValue);
                        const suffix = String(value);
                        return s.indexOf(suffix, s.length - suffix.length) !== -1;
                    case "any":
                        return String(fieldValue).indexOf(value) !== -1;
                }
                return false;
        }
        return true;
    }
}

export default filterData;

const operation = (property, value, operator) => {
    operator = operator || "~";
    switch (operator) {
        case "!~":
            return property + "" !== "" + value;
        case "~":
            return property + "" === "" + value;
        case "!=":
            return property != value;
        case "=":
        case "==":
            return property == value;
        case "<":
            return property < value;
        case ">":
            return property > value;
        case "<=":
            return property <= value;
        case ">=":
            return property >= value;
        case "===":
            return property === value;
        case "not in":
            if (Array.isArray(value) && !Array.isArray(property)) {
                if (property === null) return false;
                return value.indexOf(property) === -1;
            }
            if (value === null) return false;
            return property.indexOf(value) === -1;
        case "in":
            if (Array.isArray(value) && !Array.isArray(property)) {
                if (property === null) return false;
                return value.indexOf(property) !== -1;
            }
            if (value === null) return false;
            return property.indexOf(value) !== -1;
        case "includes":
            return !!property.find((prop) => {
                return value + "" === "" + prop;
            });
        case "not includes":
            return !property.find((prop) => {
                return value + "" === "" + prop;
            });
    }
}

export {
    operation
}