const getSearchFields = ({config}: {config: any}) => {
    let searchFields = config.searchFields;
    if (!searchFields) {
        if (config.fields?.label) {
            searchFields = ["label"];
        } else if (config.fields?.name) {
            searchFields = ["name"];
        } else {
            if (config.targetConfig) {
                searchFields = getSearchFields({config: config.targetConfig});
            }
            if (!searchFields) {
                searchFields = Object.keys(config.fields || {});
            }
        }
    }
    return searchFields;
}

export default getSearchFields;