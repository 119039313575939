// TODO implement options
// https://raw.githubusercontent.com/lodash/lodash/master/debounce.js
export default function debounce( callback, delay, options = {} ) {
    let timeout;
    let called = false;
    let pending = false;
    let func;
    const f = function (...params) {
        pending = true;
        called = false;
        let _this = this;
        clearTimeout( timeout );
        let resolve, reject;
        func = async () => {
            called = true;
            pending = false;
            callback = callback.bind(_this);
            try {
                resolve(await callback(...params));
            } catch (e) {
                reject(e);
            }
        }
        const s = new Promise((solve, eject) => {
            resolve = solve;
            reject = eject;
        });
        timeout = setTimeout( func, delay );
        s.flush = async () => {
            if (!called) {
                clearTimeout(timeout);
                func && await func();
            }
        }
        s.cancel = () => {
            clearTimeout(timeout);
        }
        return s;
    }
    f.cancel = () => {
        clearTimeout(timeout);
    }
    f.flush = async () => {
        if (!called) {
            clearTimeout(timeout);
            func && await func();
        }
    }
    f.pending = () => pending;
    return f;
}