import React from "react";
import {FilterPropsType} from "../List";
import Select from "./Select";

export default (props: FilterPropsType) => {
    return <Select
        {...props}
        values={{"1": "yes", "0": "no"}}
        translate={true}
    />
}