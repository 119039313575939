import React, {useEffect, useState} from "react";
import {FieldPropsType} from "../../Editor";
import {MultiSelect, Select} from "@mantine/core";
import Translation from "../../../Utils/Translation";
import useReadOnly from "../useReadOnly";
import list from "../../../../utils/array/list";
import {uniqueFilter} from "../../../../utils/array";

export const view = null;

export type SelectPropsType = {
    itemComponent?: React.FC<any>,
    objectValue?: boolean,
    valueField?: string,
    clearable?: boolean,
    options?: ((string|number|Record<string, any>)[])|({label: any, value: string, [key: string]: any}[])|((props: SelectPropsType&{value?: any|any[], searchValue: string, onSearchChange: (search: string) => void}) => Promise<{label: any, value: string}[]>|{label: any, value: string}[]),
    value?: any,
    selectorFunction?: (props: SelectPropsType&{newValue: any}) => any,
}&FieldPropsType;

export default (props: SelectPropsType) => {
    const [searchValue, onSearchChange] = useState('');
    const form = props.form;
    const field = props.field;
    const valueField = props.valueField ?? "value";
    const fieldSettings = props.config?.fields?.[field];
    const isList = fieldSettings.connection?.type == "hasMany";
    let value = list(props.value ?? form.values?.[field]).map((item) => item && typeof item === "object" ? item[valueField] : item);
    const fieldReadOnly = useReadOnly(props);
    if (!isList) {
        value = value[0] ?? null;
    }
    const [options, setOptions] = useState([]);
    useEffect(() => {
        let v;
        if (props.options) {
            if (props.options instanceof Function) {
                v = props.options({...props, searchValue, onSearchChange, value});
                if (Promise.resolve(v) === v) {
                    v.then((options) => {
                        setOptions(options);
                    })
                } else {
                    setOptions(v);
                }
                return () => {
                    v?.abort?.();
                }
            } else if (Array.isArray(props.options)) {
                if (props.options.find((s) => s && typeof s === "object")) {
                    setOptions(props.options);
                } else {
                    setOptions([...(props.options ?? [])].filter(Boolean).filter(uniqueFilter).map((v) => ({
                        label: v,
                        value: v
                    })));
                }
            }
        } else {
            setOptions([...(fieldSettings.values ?? [])].filter(Boolean).filter(uniqueFilter).map((v) => ({
                label: v,
                value: v
            })));
        }
    }, [props.options, fieldSettings.values, searchValue, JSON.stringify(value), fieldReadOnly]);
    return <Translations {...props} searchValue={searchValue} onSearchChange={onSearchChange} options={options} value={value} />;
}

const Translations = (props: SelectPropsType&{
    options: any[],
    value: any,
    searchValue?: string,
    onSearchChange?: (search: string) => void,
}) => {
    const ids = props.options.map((o) => o.label);
    const fieldSettings = props.config?.fields?.[props.field];
    return fieldSettings?.translate ? <Translation id={ids} base={[props.config.componentName + ".field." + props.field, props.config.componentName]}>
        <WithTranslationsSelectComp {...props} />
    </Translation> : <SelectComp {...props} />;
}

const WithTranslationsSelectComp = (props: SelectPropsType&{
    translations?: {[key: string]: string}
}&{
    options: any[],
    value: any,
    searchValue?: string,
    onSearchChange?: (search: string) => void,
}) => {
    const options = props.options.map((item) => {
        item = {...item};
        item.label = props.translations[item.label] ?? "";
        return item;
    });
    return <SelectComp {...props} options={options} />;
}

const SelectComp = (props: SelectPropsType&{
    options: any[],
    value: any,
    searchValue?: string,
    onSearchChange?: (search: string) => void,
}) => {
    const form = props.form;
    const field = props.field;
    const objectValue = props.objectValue ?? false;
    const valueField = props.valueField ?? "value";
    //console.log(field, objectValue, valueField, value);
    const fieldSettings = props.config?.fields?.[field];
    const isList = fieldSettings.connection?.type == "hasMany";
    const bases = [props.config.componentName + ".field." + props.field, props.config.componentName];
    const fieldReadOnly = useReadOnly(props);
    const Comp = isList ? MultiSelect : Select;
    //console.log(field, props);
    //console.log(field, props.options.map((item) => ({...item, value: String(item.value), label: String(item.label)})));
    //console.log(field, isList ? props.value.map((v) => v !== undefined && v !== null ? String(v) : (v ?? "")) : (props.value !== undefined && props.value !== null ? String(props.value) : (props.value ?? "")));
    return <Comp
        name={field}
        label={<Translation base={bases} id={field} />}
        withAsterisk={props.config?.fields?.[field].required}
        clearable={props.clearable ?? !props.config?.fields?.[field].required}
        value={isList ? props.value.map((v) => v !== undefined && v !== null ? String(v) : (v ?? "")) : (props.value !== undefined && props.value !== null ? String(props.value) : (props.value ?? ""))}
        placeholder={Translation({base: bases, id: field + ".placeholder", fallback: ""})}
        data={props.options.map((item) => ({...item, value: String(item.value), label: String(item.label)}))}
        searchable
        creatable={fieldSettings?.creatable}
        searchValue={props.searchValue}
        onSearchChange={props.onSearchChange}
        getCreateLabel={(query) => <Translation id="add_more_option" data={{query}} />}
        nothingFound={props.options.length === 0 && props.searchValue.length < 3 ? <Translation id="please_type_letters" /> : <Translation id="no_matches" />}
        onCreate={(query) => {
            query = query.trim();
            const item = {value: query, label: query};
            form.setFieldValue(field, query);
            return item;
        }}
        itemComponent={props.itemComponent}
        onChange={(value) => {
            let newValue: any[] = value;
            if (props.selectorFunction) {
                newValue = props.selectorFunction({...props, newValue: newValue});
                form.setFieldValue(field, newValue ?? null);
            } else {
                newValue = list(newValue);
                newValue = props.options.filter((item) => newValue.includes(String(item[valueField]))).map((item) => {
                    if (objectValue) {
                        return item;
                    }
                    return item[valueField];
                });
                form.setFieldValue(field, (isList ? newValue : newValue[0]) ?? null);
            }
        }}
        error={form.errors?.[field]}
        readOnly={fieldReadOnly || props.readOnly}
        radius="md"
    />
}