import React, {useEffect, useState} from "react";

export default ({ fallback, path }: {fallback?: any, path: string}) => {

    const [Component, setComponent] = useState(null);

    useEffect(() => {
        setComponent(null);
        import( /* webpackMode: "eager" */ "src/components/" + path)
            .then(module => {
                setComponent(() => module.default)
            })
            .catch(e => {
                setComponent(false);
            })
    }, [path]);

    return [Component ? Component : (Component === false ? fallback : null), setComponent];
}