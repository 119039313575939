import Editor from "../Editor/Editor";
import List, {ListPropsType} from "./List";
import React from "react";

export default React.forwardRef<any, Partial<ListPropsType>>((props, ref) => {
    return <List
        ref={ref}
        component="User"
        fields={{
            "actions": {
                header: (_props) => {
                    return <Editor
                        {..._props}
                        children={null}
                        onSaved={() => {
                            _props.setState({..._props.state});
                        }}
                    />
                },
                filterable: false,
                sortable: false,
                formatter: (props) => {
                    return <Editor
                        {...props}
                        values={props.data}
                        {...{[props.config.idfield]: props?.data?.[props.config.idfield]}}
                        onSaved={() => {
                            props.setState({...props.state});
                        }}
                        onRemoved={() => {
                            props.setState({...props.state});
                        }}
                        children={null}
                    />;
                }
            },
            "id": {
                filterable: true,
                sortable: true,
            },
            "lastname": {
                filterable: true,
                sortable: true,
            },
            "firstname": {
                filterable: true,
                sortable: true,
            },
            "email": {
                filterable: true,
                sortable: true,
            },
            "phone": {
                filterable: true,
                sortable: true,
            },
        }}
        {...props}
    />;
});