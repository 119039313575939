import React, {ForwardedRef, RefObject, useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Box, Button, Grid, Input, LoadingOverlay, Table} from "@mantine/core";
import {getRequest} from "../../utils/request/request";
import PromiseRequest from "../../utils/promise/PromiseRequest";
import Pagination from "./Pagination";
import {
    IconArrowDown,
    IconArrowUp,
} from '@tabler/icons-react';
import {StandardLonghandProperties} from "csstype";
import {list} from "../../utils/array";
import {useDebouncedState} from "@mantine/hooks";
import Translation from "../Utils/Translation";
import PropsProxy from "../Utils/PropsProxy";
import get from "../../utils/object/get";
import DynamicComponent from "../Utils/DynamicComponent";
import debounce from "../../utils/debounce";
import {connect} from "../../valtio";
import {minimumLengthOfSearch} from "../../config";
import {ComponentConfigType} from "../../utils/component/ComponentConfig";
import AbortablePromise from "../../utils/promise/AbortablePromise";
import {FilterType} from "../Utils/FilterTypes";
import {OrderType} from "../Utils/OrderTypes";
import filter from "../../utils/filter";

export const rowIndexField = Symbol("rowIndexField");

export const getRowIndex = (item: Record<string | symbol | number, any>) => {
    return item?.[rowIndexField];
}

export const defaultPageSizes = [10, 30, 50, 100];
export const defaultPageSize = 10;

type ItemListType<ItemType extends {[key: string]: any}> = {
    items: ItemType[],
    count: number,
    fullcount: number,
    order?: {field: string, direction: string}[],
    filter?: any
}&{
    itemsperpage?: number,
    limit?: number,
    page?: number,
    offset?: number
}&{[key: string]: any};

export type OrderMeta = {
    exclusive?: boolean
}

export type FieldType = {
    style?: StandardLonghandProperties|((props: any) => StandardLonghandProperties|undefined|null),
    className?: string|object|((props: any) => string|object),
    width?: "auto"|number,
    header?: null|string|((props: FieldProps) => React.ReactNode|null|string|number),
    sortable?: boolean,
    filterable?: boolean|React.ReactNode,
    formatter?: (props: FieldProps) => React.ReactNode|null|string|number,
    defaultSort?: "ASC"|1|"DESC"|-1,
    order?: "ASC"|1|"DESC"|-1,
    sortFunction?: (order: OrderType|undefined|null, meta: OrderMeta) => OrderType,
}

export type FilterPropsType = {
    config: any,
    state: any,
    setState: (state: any) => void,
    field: string,
    settings: FieldType
}

const DefaultFilter = ({field, settings, state, setState, config}: FilterPropsType) => {
    const [filterValue, setFilterValue] = useDebouncedState(state.headerFilters?.[field]?.value ?? '', 300);
    useEffect(() => {
        const newHeaderFilters = {...(state.headerFilters || {})};
        let changed = false;
        if (filterValue.length >= minimumLengthOfSearch) {
            newHeaderFilters[field] = {
                field,
                operator: "LIKE",
                value: filterValue
            }
            changed = true;
        } else if (newHeaderFilters.hasOwnProperty(field)) {
            delete newHeaderFilters[field];
            changed = true;
        }
        if (changed) {
            setState({...state, headerFilters: newHeaderFilters});
        }
    }, [filterValue]);
    return <Input
        style={{minWidth: "70px"}}
        defaultValue={filterValue}
        onChange={(event) => setFilterValue(event.currentTarget.value)}
    />
}

const BasicFilter = ({field, settings, state, setState, config}: FilterPropsType) => {
    if (!config?.fields?.[field]?.datatype) return null;
    return <DynamicComponent
        path={"List/Filters/" + config?.fields?.[field]?.datatype}
        fallback={<DefaultFilter
            config={config}
            state={state}
            setState={setState}
            field={field}
            settings={settings}
        />}
        config={config}
        state={state}
        setState={setState}
        field={field}
        settings={settings}
    />
}

export type FieldHeaderProps = {
    component: string,
    config: any,
    state: any,
    setState: (state: any) => void,
    field: string,
    settings: FieldType
};

const FieldHeader = ({component, config, field, settings, state, setState}: FieldHeaderProps) => {

    let filter = null;
    const filterable = settings.filterable || settings.filterable === undefined;
    if (filterable) {
        if (filterable === true) {
           filter = <BasicFilter
               field={field}
               settings={settings}
               state={state}
               setState={setState}
               config={config}
           />
        } else if (settings.filterable instanceof Function) {
            const FilterComponent: React.FC = settings.filterable as any;
            // @ts-ignore
            filter = <FilterComponent field={field} settings={settings} />
        } else {
            const FilterComponent: any = settings.filterable as any;
            filter = <FilterComponent.type ref={FilterComponent.ref} {...FilterComponent.props} />
        }
    }

    const order = state.headerOrders?.[field];

    let sort = null;
    if (settings.sortable || settings.sortable === undefined) {
        sort = <Button.Group orientation="vertical">
            <Button
                style={{padding: 0}}
                compact
                variant={order?.direction === "ASC" || order?.direction === 1 ? "filled" : "subtle"}
                fullWidth
                onClick={() => {
                    const newOrder = {...(state.headerOrders ?? {})};
                    if (order?.direction === "ASC" || order?.direction === 1) {
                        delete newOrder[field];
                    } else {
                        if (order) {
                            delete newOrder[field];
                        }
                        newOrder[field] = {field, direction: "ASC"};
                    }
                    setState({...state, headerOrders: newOrder});
                }}
            >
                <IconArrowUp />
            </Button>
            <Button
                style={{padding: 0}}
                compact
                variant={order?.direction === "DESC" || order?.direction === -1 ? "filled" : "subtle"}
                fullWidth
                onClick={() => {
                    const newOrder = {...(state.headerOrders ?? {})};
                    if (order?.direction === "DESC" || order?.direction === -1) {
                        delete newOrder[field];
                    } else {
                        if (order) {
                            delete newOrder[field];
                        }
                        newOrder[field] = {field, direction: "DESC"};
                    }
                    setState({...state, headerOrders: newOrder});
                }}
            >
                <IconArrowDown />
            </Button>
        </Button.Group>;
    }

    return <th style={{width: settings.width === "auto" ? "auto" : String(settings.width) + "%"}}>
        <Grid gutter="xs" columns={12} style={{flexWrap: "nowrap"}}>
            <Grid.Col span="auto">
                {typeof settings.header === "string" ?
                    <span style={{whiteSpace: "nowrap"}}>
                        <Translation base={[component]} id={settings.header} />
                    </span>
                    : (
                        settings.header ?
                            <PropsProxy
                                component={component}
                                config={config}
                                field={field}
                                settings={settings}
                                state={state}
                                setState={setState}
                            >
                                {settings.header}
                            </PropsProxy>
                        :
                            <span style={{whiteSpace: "nowrap"}}>
                                <Translation base={[component]} id={field} />
                            </span>
                    )
                }
                {filter}
            </Grid.Col>
            <Grid.Col span="content">{sort}</Grid.Col>
        </Grid>
    </th>;
}

export type FieldProps = {
    state: any,
    setState: (state: any) => void,
    component: string,
    config: ComponentConfigType,
    value: any,
    settings: FieldType,
    field: string,
    data: any
};

const Field = ({component, config, value, settings, field, data, ...rest}: FieldProps) => {
    if (settings.formatter) {
        return <td>
            <PropsProxy
                component={component}
                config={config}
                value={value}
                settings={settings}
                field={field}
                data={data}
                {...rest}
            >
                {settings.formatter}
            </PropsProxy>
        </td>;
    } else if (config?.fields?.[field]?.datatype) {
        return <td>
            <DynamicComponent
                path={"List/Fields/" + config?.fields?.[field]?.datatype}
                fallback={value}
                component={component}
                config={config}
                value={value}
                settings={settings}
                field={field}
                data={data}
                {...rest}
            />
        </td>;
    }
    return <td>{value}</td>;
}

export type ListPropsType = {
    ref?: RefObject<any>|ForwardedRef<any>,
    listId?: string,
    rowClassName?: string|((props: ListPropsType&{
        config: ComponentConfigType,
        state: ItemListType<Record<string, any>>,
        setState: (state: any) => void,
        element: Record<string, any>
    }) => string),
    filter?: any,
    view?: any,
    order?: any,
    value?: any[],
    itemsperpage?: number,
    component: string,
    fields: {[key: string]: FieldType},
};



function filterRow(_filter: FilterType|FilterType[], row: object) {
    return filter({data: row, filter: _filter});
}

function filterData(filter: FilterType|FilterType[], data: object[]) {
    if (!filter) return data;
    return [...data].filter((row) => {
        return filterRow(filter, row);
    });
}

function sortRow<ItemType extends any,>(order: OrderType, a: ItemType, b: ItemType) {
    let left = get(a, order.field);
    let right = get(b, order.field);
    const direction = order.direction.toUpperCase();
    if (typeof left === "string" && (right === null || right === undefined)) {
        right = "";
    }
    if (typeof right === "string" && (left === null || left === undefined)) {
        left = "";
    }
    if (typeof left === "string" && typeof right === "string") {
        if (left.length !== right.length) {
            left = left.length;
            right = right.length;
        }
    }
    if (direction === "DESC") {
        if (left > right) return -1;
        if (left < right) return +1;
    } else if (direction === "ASC") {
        if (left > right) return +1;
        if (left < right) return -1;
    }
    return 0;
}

function sortRows<ItemType extends any, >(orders: OrderType[], rows: ItemType[]): ItemType[] {
    const groupBy = function (xs: any[], key: string): any[] {
        return xs.reduce(function (rv, x) {
            (rv[get(x, key)] = rv[get(x, key)] || []).push(x);
            return rv;
        }, {});
    };
    const order = orders.shift();
    if (!order) {
        return [...rows];
    }
    rows.sort((a, b) => sortRow<ItemType>(order, a, b));
    if (orders.length) {
        const groups = groupBy(rows, order.field);
        return Object.values(groups).map<ItemType[]>((group: ItemType[]) => {
            group.sort((a, b) => sortRow<ItemType>(order, a, b));
            return sortRows([...orders], group);
        }).reduce<ItemType[]>((all: ItemType[], current) => ([...all, ...current]), []);
    } else {
        return [...rows];
    }
}

function sortData(order: OrderType|OrderType[], data: object[]) {
    if (!order) return data;
    data = [...data];
    if (Array.isArray(order)) {
        let orders = [...order];
        data = sortRows(orders, data);
        return data;
    } else if (order) {
        data = [...data];
        data.sort((a, b) => sortRow(order, a, b));
        return data;
    }
    return data;
}

function viewDots (obj: {[key: string]: any}, key: string): {[key: string]: any} {
    if (key.includes(".")) {
        const keys: string[] = key.split(".");
        obj[keys.shift() as string] = viewDots({}, keys.join("."));
    } else {
        obj[key] = null;
    }
    return obj;
}


type ComponentViewType = any;

function mergeViews (...views: ComponentViewType[]): ComponentViewType|null {
    if (views.length === 0) return null;
    if (views.length === 1) return views[0];
    const view: ComponentViewType = {...views[0]};
    views.splice(1, views.length - 1).forEach((aView) => {
        Object.entries(aView).forEach(([key, value]) => {
            if (view.hasOwnProperty(key) && view[key] && typeof view[key] === "object" && value && typeof view[key] === "object") {
                view[key] = mergeViews(view[key] as ComponentViewType, value as ComponentViewType);
            } else if (view.hasOwnProperty(key) && view[key] && typeof view[key] === "string" && value && typeof view[key] === "string" && value !== view[key]) {
                throw new Error(`different view for '${key}' field in views!`);
            } else {
                view[key] = null;
            }
        });
    });
    return view;
}


export default connect((state) => {
    return {
        state: state,
    }
})(React.forwardRef((props: ListPropsType&{state?: any}, ref) => {
    const defaultState = props?.state?.lists?.[props.listId] ?? {
        page: 1,
        itemsperpage: props.itemsperpage ?? defaultPageSize,
        component: props.component,
        count: 0,
        fullcount: 0,
        order: [],
        filter: [],
        items: [],
        navigation: {
            currpage: 1,
            pagecount: 0,
            rangestart: 0,
            rangeend: 0,
        }
    }
    const [state, _setState] = useState<ItemListType<Record<string, any>>>(defaultState);
    const setState = useCallback((state) => {
        _setState(state);
        if (props.listId) {
            if (!props.state.lists) {
                props.state.lists = {
                    [props.listId]: state
                };
            } else {
                props.state.lists[props.listId] = state;
            }
        }
    }, [props.listId]);
    const [loading, setLoading] = useState(true);
    const propsRef = useRef<ListPropsType>(null);
    propsRef.current = props;
    const load = useCallback((state) => {
        const props = propsRef.current;
        setLoading(true);
        let req: PromiseRequest|AbortablePromise;
        req = getRequest("/components", {action: props.component + ".config"});
        req.then((config) => {
            let view = props.view;
            if (view) {
                if (Array.isArray(view)) {
                    view = view.reduce((all, key) => viewDots({...all}, key), {});
                    view = mergeViews(view, Object.keys(props.fields).reduce((all, key) => viewDots({...all}, key), {}));
                }
            }
            const request = {
                page: state.page,
                view,
                itemsperpage: state.itemsperpage,
                order: list(props.order).concat(Object.values(state.headerOrders ?? {})),
                filter: list(props.filter).concat(Object.values(state.headerFilters ?? {})),
                option: props.component,
            };
            if (props.value) {
                let data: Record<string | symbol | number, any>[] = [...props.value].map((item, idx) => {
                    return {
                        [rowIndexField]: idx,
                        ...item
                    }
                });
                data = filterData(request.filter, data);
                data = sortData(request.order, data);
                const pageSize = request.itemsperpage > 0 ? request.itemsperpage : data.length;
                const rangestart = ((request.page - 1) * pageSize) + 1;
                const rangeend = Math.min((request.page - 1) * pageSize + pageSize, data.length);
                const items = data.slice(rangestart-1, rangeend);

                req = new AbortablePromise((onFulfilled) => {
                    onFulfilled({
                        page: request.page,
                        view: request.view,
                        itemsperpage: props.itemsperpage ?? defaultPageSize,
                        component: props.component,
                        count: data.length,
                        fullcount: props.value.length,
                        order: request.order,
                        filter: request.filter,
                        items,
                        navigation: {
                            currpage: request.page,
                            pagecount: Math.ceil(data.length / Math.max(pageSize, 1)),
                            rangestart,
                            rangeend,
                        }
                    });
                });
            } else {
                req = getRequest("/components", request);
            }
            req.then((res) => {
                setState({
                    ...state,
                    ...res,
                    view: request.view,
                    filter,
                    order: props.order,
                    config
                });
                setLoading(false);
            }).catch((e) => {
                console.error(e);
            });
        });
        return req;
    }, []);
    useEffect(() => {
        let req: PromiseRequest|AbortablePromise = load(state);
        return () => {
            setLoading(false);
            req?.abort?.();
        }
    }, [props.component, props.order, props.filter, JSON.stringify(props.value)]);
    const debounceLoad = useCallback(debounce(load, 300), []);
    useEffect(() => {
        debounceLoad({...state, itemsperpage: props.itemsperpage, page: 1});
    }, [props.itemsperpage]);
    useImperativeHandle(ref, function getRefValue() {
        return {
            // new ref value...
            setState(state) {
                debounceLoad(state);
            },
            reload() {
                debounceLoad(state);
            }
        }
    }, [state]);
    const fieldCount = Object.keys(props.fields).length;
    return <Box pos="relative">
        <LoadingOverlay visible={loading} />
        {state.config ? <>
            <Table verticalSpacing="xs" highlightOnHover withBorder withColumnBorders>
                <thead>
                    <tr>
                        {Object.entries(props.fields).map(([field, settings]) => {
                            return <FieldHeader
                                key={field}
                                config={state.config}
                                component={props.component}
                                state={state}
                                setState={debounceLoad}
                                field={field}
                                settings={settings}
                            />
                        })}
                    </tr>
                </thead>
                <tbody>
                    {!loading ? (state.items.length ? state.items.map((element) => {
                        return <tr key={getRowIndex(element) ?? element[state.config.idfield]} className={typeof props.rowClassName === "function" ? props.rowClassName({
                            ...props,
                            component: props.component,
                            fields: props.fields,
                            filter,
                            order: props.order,
                            listId: props.listId,
                            itemsperpage: props.itemsperpage,
                            config: state.config,
                            state: state,
                            setState: debounceLoad,
                            element: element,
                        }) : props.rowClassName}>
                            {Object.entries(props.fields).map(([field, settings]) => {
                                return <Field
                                    {...props}
                                    key={field}
                                    component={props.component}
                                    config={state.config}
                                    state={state}
                                    setState={debounceLoad}
                                    field={field}
                                    settings={settings}
                                    data={element}
                                    value={get(element, field)}
                                />
                            })}
                        </tr>
                    }) : <tr>
                        <td colSpan={fieldCount}>
                            <Translation id="no_matches" />
                        </td>
                    </tr>) : null}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={fieldCount} />
                    </tr>
                </tfoot>
            </Table>
            <Pagination
                state={state}
                setState={debounceLoad}
                loading={loading}
            />
        </> : null}
    </Box>;
}));