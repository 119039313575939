import {ActionIcon, Button, Flex, Grid, Group, Menu, Modal, rem, Text, useMantineTheme} from "@mantine/core";
import Translation from "../../Utils/Translation";
import Confirm from "../../Utils/Confirm";
import {getRequest} from "../../../utils/request/request";
import React, {useEffect, useState} from "react";
import {FieldProps} from "../List";
import {connect} from "../../../valtio";
import hasActionPrivilege from "../../../utils/component/hasActionPrivilege";
import DynamicComponent from "../../Utils/DynamicComponent";
import {IconChevronDown} from "@tabler/icons-react";

export default connect((state) => {
    return {
        session: state,
    }
})((props: FieldProps&{session?: {context: any}}&{onStateChanged?: () => void}) => {
    if (!props.data[props.config.idfield]) {
        return <Grid gutter="xs">
            <Grid.Col span={6} style={{display: "flex"}}>
                <Flex align="center">
                    <Translation id={props.value} base={[props.config.componentName + ".field." + props.field, props.config.componentName]} />
                </Flex>
            </Grid.Col>
        </Grid>;
    }
    const states = Object.keys(props.config?.fields?.[props.field]?.states ?? {});

    const privProceed = hasActionPrivilege({
        config: props.config,
        action: "proceedState",
        data: JSON.parse(JSON.stringify(props.data))
    });

    const privRollback = hasActionPrivilege({
        config: props.config,
        action: "rollbackState",
        data: JSON.parse(JSON.stringify(props.data))
    });

    const [state, setState] = useState(null);
    useEffect(() => {
        setState(null);
    }, [props?.data?.state]);

    const theme = useMantineTheme();

    const [opened, setOpened] = useState(false);

    const hasRollBackState = privRollback && states.indexOf(String(props.value)) > 0;
    const hasProceedState = privProceed && props.value < states[states.length - 1];
    const [message, setMessage] = useState(null);

    return <>
        <Modal
            title={<Translation id="message" />}
            opened={!!message}
            centered
            onClose={() => setMessage(null)}
        >
            <Text>{typeof message === "string" ? <Translation id={message} /> : message}</Text>
            <Group mt="xl" position="right">
                <Button
                    onClick={() => setMessage(null)}
                >
                    <Translation id="ok" />
                </Button>
            </Group>
        </Modal>
        <Grid gutter="xs">
            <Grid.Col span={6} style={{display: "flex"}}>
                <Flex align="center">
                    <Translation id={props.value} base={[props.config.componentName + ".field." + props.field, props.config.componentName]} />
                </Flex>
            </Grid.Col>
            <Grid.Col span={6}>

                {hasRollBackState && <Confirm
                    opened={opened}
                    title={<Translation id="rollback" />}
                    body={<>
                        <DynamicComponent
                            {...props}
                            path="Editor/Fields/belongsTo/State"
                            field="state"
                            action="rollbackState"
                            value={undefined}
                            form={{
                                values: {
                                    state: state ?? states[states.indexOf(String(props.value)) - 1]
                                },
                                errors: {},
                                setFieldValue: (field, value) => {
                                    setState(value);
                                }
                            }}
                        />
                    </>}
                    onCancel={() => {
                        setOpened(false);
                    }}
                    onAccept={() => {
                        getRequest("/components", {
                            action: props.config.componentName + ".rollbackState",
                            [props.config.idfield]: props.data[props.config.idfield],
                            userCall: true,
                            halt_at_state: state ?? states[states.indexOf(String(props.value)) - 1],
                        }).then((data) => {
                            if (data.message) {
                                setMessage(data.message);
                            } else {
                                props.setState({...props.state});
                                props?.onStateChanged?.();
                            }
                        });
                    }}
                >
                    {() => null}
                </Confirm>}

                <Group style={{gap: 0, flexFlow: "nowrap"}}>
                    {hasProceedState && <Confirm
                        title={<Translation id="proceed" />}
                        body={<>
                            <DynamicComponent
                                {...props}
                                path="Editor/Fields/belongsTo/State"
                                field="state"
                                action="proceedState"
                                value={undefined}
                                disabled={true}
                                form={{
                                    values: {
                                        state: states[states.indexOf(String(props.value)) + 1]
                                    },
                                    errors: {},
                                    setFieldValue: (field, value) => {
                                        setState(value);
                                    }
                                }}
                            />
                        </>}
                        onAccept={() => {
                            getRequest("/components", {
                                action: props.config.componentName + ".proceedState",
                                [props.config.idfield]: props.data[props.config.idfield],
                                userCall: true,
                                halt_at_state: state,
                            }).then((data) => {
                                if (data.message) {
                                    setMessage(data.message);
                                } else {
                                    props.setState({...props.state});
                                    props?.onStateChanged?.();
                                }
                            });
                        }}
                    >
                        <Button
                            style={hasRollBackState ? {
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                            } : undefined}
                            disabled={states.indexOf(String(props.value)) > states.length - 1}
                        >
                            <Translation id="proceed" />
                        </Button>
                    </Confirm>}
                    {
                        hasProceedState && hasRollBackState ?
                        <Menu transitionProps={{ transition: 'pop' }} position="bottom-end">
                            <Menu.Target>
                                <ActionIcon
                                    variant="filled"
                                    color={theme.primaryColor}
                                    size={36}
                                    style={{
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        border: 0,
                                        borderLeft: "rem(1px) solid var(--mantine-color-body)",
                                    }}
                                >
                                    <IconChevronDown style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                                </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Item
                                    onClick={() => setOpened(true)}
                                >
                                    <Translation id="rollback" />
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu> : (hasRollBackState ? <Button
                            onClick={() => setOpened(true)}
                            >
                                <Translation id="rollback" />
                            </Button> : null)}
                </Group>
            </Grid.Col>
        </Grid>
    </>
});