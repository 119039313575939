import {FieldPropsType} from "../../Editor";
import Translation from "../../../Utils/Translation";
import {Box, Button, Flex, Input} from "@mantine/core";
import React from "react";
import {get} from "../../../../utils/object";
import DynamicComponent from "../../../Utils/DynamicComponent";
import {IconTrash} from "@tabler/icons-react";
import Confirm, {deleteProps} from "../../../Utils/Confirm";
import useReadOnly from "../useReadOnly";

export const view = null;

export default (props: FieldPropsType) => {
    const form = props.form;
    const field = props.field;
    const fieldName = field.split(".");
    const currentField = fieldName.shift();
    const fieldSettings = props.config?.fields?.[currentField];
    const value = get(form.values, field);
    const fieldReadOnly = useReadOnly(props);
    return (
        <>
            <Input.Wrapper
                label={<Translation base={[props.config.componentName + ".field." + currentField, props.config.componentName]} id={currentField} />}
                withAsterisk={fieldSettings.required}
                error={form.errors?.[field]}
            >
                {null}
            </Input.Wrapper>
            <Flex gap="md">
                {value?.map((_null, idx) => {
                    return <Flex
                        key={idx}
                        gap="xs"
                        direction="column"
                        justify="flex-end"
                        align="center"
                    >
                        <Box w={240}>
                            <DynamicComponent
                                idx={idx}
                                path={"Editor/Fields/belongsTo/" + fieldSettings.datatype}
                                {...props}
                                field={field + "." + idx}
                            />
                        </Box>
                        <Box w={240}>
                            {!(fieldReadOnly || props.readOnly) ? <Confirm
                                {...deleteProps}
                                onAccept={() => {
                                    const newValue = [...value];
                                    newValue.splice(idx, 1);
                                    form.setFieldValue(field, newValue);
                                }}
                            >
                                <Button
                                    variant="filled"
                                    fullWidth
                                >
                                    <IconTrash />
                                </Button>
                            </Confirm> : null}
                        </Box>
                    </Flex>
                })}
                {!(fieldReadOnly || props.readOnly) ? <Flex
                    gap="xs"
                    direction="column"
                    justify="center"
                    align="center"
                >
                    <Box w={240}>
                        <DynamicComponent
                            path={"Editor/Fields/belongsTo/" + fieldSettings.datatype}
                            {...props}
                            form={{
                                ...form,
                                setFieldValue: (_field, _value) => {
                                    const newValue = [...(value ?? [])];
                                    newValue.push(_value);
                                    form.setFieldValue(field, newValue);
                                }
                            }}
                            field={field + "." + (value?.length ?? 0)}
                        />
                    </Box>
                </Flex> : null}
            </Flex>
        </>
    )
}