import React, {CSSProperties, useEffect, useRef} from "react";
import {kebabCase} from "../../utils/string/change-case";

const StyleInjector = ({style}: {style: string}) => {
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current) {
            ref.current.innerHTML = style;
            return () => {
                if (ref.current) {
                    ref.current.innerHTML = '';
                }
            }
        }
    }, []);
    return <style ref={ref} type="text/css" />;
}

export default StyleInjector;

type CSSPropType = {[K in keyof CSSProperties]: CSSProperties[K]|{[key: string]: CSSProperties[K]}};

export type StyleType = {[key: string]: CSSPropType};
export type VarType = {[key: string]: string|number|null|{[key: string]: string|number|null}};
export type ThemeType = {[key: string]: string|number|null|{[key: string]: string|number|null}};

const aliasSymbol = Symbol("$alias");

function hashCode(s) {
    let h = 0, l = s.length, i = 0;
    if ( l > 0 )
        while (i < l)
            h = (h << 5) - h + s.charCodeAt(i++) | 0;
    return String(h).split('').map((a) => a === '-' ? 'x' : String.fromCharCode(Number(a) + 97)).join('');
}

const typeAliasSymbol = Symbol("$typeAlias");

export const create = <T = StyleType>(style: T): T => {
    return Object.keys(style).reduce((style, className) => {
        style[className] = {...style[className]};
        style[className][aliasSymbol] = hashCode(`${className}-${new Date().getTime()}`);
        return style;
    }, {...style, [typeAliasSymbol]: hashCode("style")});
}

export const defineVars = <T = VarType>(vars: T): T => {
    return Object.keys(vars).reduce((vars, varName) => {
        vars[varName] = typeof vars[varName] === "object" ? {...vars[varName]} : {default: vars[varName]};
        vars[varName][aliasSymbol] = hashCode(`${varName}-${new Date().getTime()}`);
        return vars;
    }, {...vars, [typeAliasSymbol]: hashCode("variables")});
}

export const createTheme = (vars: VarType, theme: ThemeType): ThemeType => {
    return [...Object.keys(vars), ...Object.keys(theme)].filter((value, index, array) => array.indexOf(value) === index).reduce((newTheme, name) => {
        // @ts-ignore
        newTheme[name] = {...(typeof vars[name] === "object" ? {...vars[name]} : {default: vars[name]}), ...{...(typeof theme[name] === "object" ? {...theme[name]} : {default: theme[name]})}};
        newTheme[name][aliasSymbol] = hashCode(`${name}-${new Date().getTime()}`);
        return newTheme;
    }, {[typeAliasSymbol]: hashCode("theme")});
}

export const props = (...styles: CSSPropType[]) => {
    return {
        className: styles.filter(Boolean).map((style) => {
            if (style[aliasSymbol as unknown as string]) {
                return style[aliasSymbol as unknown as string];
            }
            if (process.env.NODE_ENV === "development") {
                throw new Error(`Style alias for '${JSON.stringify(style)}' is not defined.`);
            }
            return "";
        }).join(" ").trim()
    }
}

const compileStyle = (style: StyleType) => {
    return Object.entries(style).reduce((compiled, [className, classProps]) => {
        return compiled + Object.entries(Object.entries(classProps).reduce((compiled, [styleName, value]) => {
            if ((styleName as unknown as symbol) === aliasSymbol) {
                return compiled;
            }
            className = classProps[aliasSymbol] ?? className;
            if (value && typeof value === 'object') {
                return Object.entries(value).reduce((compiled, [state, value]) => {
                    state = state.trim();
                    if (state === "default") {
                        const key = `.${className}`;
                        compiled[key] = compiled[key] ?? {};
                        compiled[key][`${kebabCase(styleName)}`] = value;
                    } else if (state.trim().slice(0, 1) === ":") {
                        const key = `.${className}${state}`;
                        compiled[key] = compiled[key] ?? {};
                        compiled[key][`${kebabCase(styleName)}`] = value;
                    } else if (state.trim().slice(0, 1) === "@") {
                        const key = `${state}{.${className}`;
                        compiled[key] = compiled[key] ?? {};
                        compiled[key][`${kebabCase(styleName)}`] = value;
                    }
                    return compiled;
                }, compiled);
            }
            const key = `.${className}`;
            compiled[key] = compiled[key] ?? {};
            compiled[key][`${kebabCase(styleName)}`] = value;
            return compiled;
        }, {})).sort((a, b) => a[0].localeCompare(b[0])).reduce((compiled, [className, values]) => {
            return compiled + `\n${className}{${Object.entries(values).reduce((compiled, [styleName, value]) => {
                if (value !== null) {
                    return compiled + `${styleName}: ${value};`;
                }
                return compiled;
            }, '')}${'}'.repeat(className.split('{').length)}`;
        }, '');
    }, '');
}

export const injectStyles = (style: StyleType) => {
    const compiledStyle = compileStyle(style);
    return () => <StyleInjector style={compiledStyle} />
}

export const inject = (style: StyleType) => {
    const Injected = injectStyles(style);
    return <T,>(Component: T): T => {
        // @ts-ignore
        return React.forwardRef((props, ref) => {
            // @ts-ignore
            const roasted = <Component ref={ref} {...props} />;
            return <>
                <Injected />
                {roasted}
            </>
        });
    }
}