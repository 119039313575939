import React from "react";

type PropsType = {overrideProps?: boolean, children?: any, [otherProps: string]: any};

const PropsProxy = React.forwardRef<any, PropsType>((propsTop: PropsType, ref) => {
    propsTop = {...propsTop};
    const overrideProps = propsTop.overrideProps === undefined ? true : propsTop.overrideProps;
    delete propsTop.overrideProps;
    if (propsTop.children instanceof Function) {
        return <propsTop.children ref={ref} {...propsTop} />;
    }
    if (propsTop.children instanceof String) {
        return propsTop.children;
    }
    let innerRef: any = undefined;
    if (propsTop.children.length > 1 && ref) {
        console.error(`PropsProxy got a ref but it has got more than one child!`);
    } else {
        innerRef = ref;
    }
    return React.Children.map(propsTop.children, (child, idx) => {
        if (child === null || child === undefined) {
            return child;
        }
        if (child instanceof Function) {
            const Child = child;
            return <Child ref={innerRef} key={idx} {...propsTop} />;
        }
        if (child instanceof Object) {
            const children = child.props.children;
            let props = {...child.props};
            delete props.children;
            if (overrideProps) {
                props = {...props, ...propsTop};
            } else {
                props = {...propsTop, ...props};
            }
            if (innerRef) {
                props.ref = (el: any) => {
                    child.ref && child.ref(el);
                    innerRef && innerRef(el);
                };
            }
            props.key = idx;
            // MAYBE TODO https://github.com/facebook/react/issues/8873#issuecomment-287873307
            return <child.type ref={child.ref} {...props}>{children}</child.type>;
        } else {
            return child;
        }
    });
});

PropsProxy.displayName = "PropsProxy";

export default PropsProxy;