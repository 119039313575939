import React, {Fragment, useEffect} from "react";
import Translation from "../../../Utils/Translation";
import {Input} from "@mantine/core";
import {FieldPropsType} from "../../Editor";
import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import useReadOnly from "../useReadOnly";
import StyleInjector from "../../../Utils/StyleInjector";

export const view = null;

export default (props: FieldPropsType) => {
    const form = props.form;
    const field = props.field;
    const value = form.values?.[field] ?? "";
    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Superscript,
            SubScript,
            Highlight,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ],
        onUpdate({editor}) {
            form.setFieldValue(field, editor.getHTML());
        },
        content: value,
    });
    const bases = [props.config.componentName + ".field." + props.field, props.config.componentName];
    const fieldReadOnly = useReadOnly(props);
    const readOnly = fieldReadOnly ?? props.readOnly;
    useEffect(() => {
        if (editor?.getHTML() !== value) {
            editor?.commands?.setContent(value);
        }
    }, [value]);
    editor?.setOptions({editable: !readOnly});
    return (
        <Fragment>
            <StyleInjector style={`
            .mantine-RichTextEditor-content {
                min-height: 0;
            }
            .mantine-RichTextEditor-content > div[contenteditable="true"] {
                min-height: 320px;
            }
            `} />
            <Input.Wrapper
                label={props.label ?? <Translation base={bases} id={field} />}
                withAsterisk={props.config?.fields?.[field]?.required}
                error={form.errors?.[field]}
            >
                <RichTextEditor
                    editor={editor}
                    placeholder={Translation({base: bases, id: field + ".placeholder", fallback: ""})}
                >
                    {!readOnly ? <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Bold />
                            <RichTextEditor.Italic />
                            <RichTextEditor.Underline />
                            <RichTextEditor.Strikethrough />
                            <RichTextEditor.ClearFormatting />
                            <RichTextEditor.Highlight />
                            <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.H1 />
                            <RichTextEditor.H2 />
                            <RichTextEditor.H3 />
                            <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Blockquote />
                            <RichTextEditor.Hr />
                            <RichTextEditor.BulletList />
                            <RichTextEditor.OrderedList />
                            <RichTextEditor.Subscript />
                            <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Link />
                            <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.AlignLeft />
                            <RichTextEditor.AlignCenter />
                            <RichTextEditor.AlignJustify />
                            <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                    </RichTextEditor.Toolbar> : null}

                    <RichTextEditor.Content mih="320px" />
                </RichTextEditor>
            </Input.Wrapper>
        </Fragment>
    );
    /*return <TextInput
        label={<Translation base={[props.config.componentName + ".field." + props.field, props.config.componentName]} id={field} />}
        value={form.values?.[field] ?? ''}
        onChange={(event) => form.setFieldValue(field, event.currentTarget.value)}
        error={form.errors?.[field]}
        radius="md"
    />*/
}