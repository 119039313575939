import React, {Fragment, useMemo} from "react";
import Editor from "../Editor/Editor";
import List, {ListPropsType} from "./List";
import hasActionPrivilege from "../../utils/component/hasActionPrivilege";
import {Button} from "@mantine/core";
import {IconFileTypePdf} from "@tabler/icons-react";
import list from "../../utils/array/list";

export default React.forwardRef<any, Partial<ListPropsType&{[key: string]: any}>>((props, ref) => {
    const header = useMemo(() => {
        return (_props) => {
            return list(props.disabledActions).indexOf("create") === -1 ? <Editor
                {..._props}
                children={null}
                actions={props.actions}
                exists={props.exists}
                valuesParser={props.valuesParser}
                onSaved={() => {
                    if (!props.value) {
                        _props.setState({..._props.state});
                    }
                }}
            /> : null
        }
    }, [list(props.disabledActions).join(","), props.actions, props.exists, props.valuesParser, JSON.stringify(props.value)]);
    const formatter = useMemo(() => {
        return (_props) => {
            return <>
                <Editor
                    {..._props}
                    {...{[_props.config.idfield]: _props?.data?.[_props.config.idfield]}}
                    actions={props.actions}
                    exists={props.exists}
                    valuesParser={props.valuesParser}
                    onSaved={() => {
                        if (!props.value) {
                            _props.setState({..._props.state});
                        }
                    }}
                    onRemoved={() => {
                        if (!props.value) {
                            _props.setState({..._props.state});
                        }
                    }}
                    values={_props.data}
                    children={null}
                    actionButtons={hasActionPrivilege({
                        config: _props.config,
                        action: "pdf",
                        data: _props.data
                    }) ? <Button
                        variant="outline"
                        onClick={() => {
                            window.open("/components?action=" + _props.config.componentName + ".pdf&" + _props.config.idfield + "=" + _props?.data?.[_props.config.idfield], "_blank");
                        }}
                    >
                        <IconFileTypePdf />
                    </Button> : null}
                />
            </>;
        }
    }, [props.actions, props.exists, props.valuesParser, JSON.stringify(props.value)]);
    return <Fragment>
        <List
            ref={ref}
            component="Comment"
            fields={{
                "actions": {
                    header,
                    filterable: false,
                    sortable: false,
                    width: 1,
                    formatter
                },
                "text": {
                    filterable: true,
                    sortable: true,
                    width: 1,
                },
            }}
            {...props}
        />
    </Fragment>;
});