import React, {useEffect, useState} from "react";
import filter from "../../../utils/filter";
import {FieldPropsType} from "../Editor";

export default (props: FieldPropsType) => {
    const field = props.field;
    const fieldName = field.split(".");
    const index = fieldName.length > 1 ? Number(fieldName.pop()) : null;
    const isList = !isNaN(index);
    const currentField = fieldName.shift();

    const fieldReadOnly = props?.config?.actions?.[props.action]?.fields?.[currentField]?.readOnly ?? props.config?.fields?.[currentField]?.readOnly;
    const [readOnly, setReadOnly] = useState(typeof fieldReadOnly === "boolean" ? fieldReadOnly : false);
    useEffect(() => {
        if (fieldReadOnly && fieldReadOnly !== true) {
            setReadOnly(filter({
                filter: JSON.parse(JSON.stringify(fieldReadOnly)),
                data: JSON.parse(JSON.stringify(props.form.values))
            }));
        }
    }, [fieldReadOnly, props.session?.context, props.form.values]);
    return props.readOnly || readOnly;
}