import React from "react";
import Translation from "../../../Utils/Translation";
import {DateTimePicker} from "@mantine/dates";
import {FieldPropsType} from "../../Editor";
import moment from "moment";
import useReadOnly from "../useReadOnly";
import {strtotime} from "locutus/php/datetime";

export const view = null;

export default (props: FieldPropsType) => {
    const form = props.form;
    const field = props.field;
    const fieldReadOnly = useReadOnly(props);
    const min = props.config?.fields?.[field].min;
    const max = props.config?.fields?.[field].max;
    return <DateTimePicker
        name={field}
        valueFormat="YYYY-MM-DD hh:mm:ss"
        label={<Translation base={[props.config.componentName + ".field." + props.field, props.config.componentName]} id={field} />}
        withAsterisk={props.config?.fields?.[field].required}
        value={(form.values?.[field] ? moment(form.values?.[field]).toDate() : undefined)}
        minDate={min ? moment(strtotime(min)*1000).toDate() : undefined}
        maxDate={max ? moment(strtotime(max)*1000).toDate() : undefined}
        onChange={(value) => form.setFieldValue(field, moment(value).format("YYYY-MM-DD HH:mm:ss"))}
        error={form.errors?.[field]}
        readOnly={fieldReadOnly || props.readOnly}
        radius="md"
    />
}