import {ComponentConfigFieldType, ComponentConfigType} from "../../../../utils/component/ComponentConfig";
import {FieldPropsType} from "../../Editor";
import useReadOnly from "../useReadOnly";
import React, {useCallback, useMemo} from "react";
import {getRowIndex, rowIndexField} from "../../../List/List";
import {Button, Grid, Input} from "@mantine/core";
import Translation from "../../../Utils/Translation";
import {get} from "../../../../utils/object";
import ComponentConfig from "../../../Utils/ComponentConfig";
import DynamicComponent from "../../../Utils/DynamicComponent";
import Confirm, {deleteProps} from "../../../Utils/Confirm";
import {IconPlus, IconTrash} from "@tabler/icons-react";

export const view = ({field, view, config: parentConfig, fieldSettings}: {field: string, view: Record<string, any>, config: ComponentConfigType, fieldSettings: ComponentConfigFieldType}) => {
    //const foreignKey = fieldSettings.connection.foreignKey ?? (snakeCase(parentConfig.componentName) + '_id');
    const localKey = fieldSettings.connection.localKey ?? parentConfig.idfield;
    view[localKey] = view[localKey] ?? null;
    view[field] = {
        id: null,
        project: {
            id: null,
        },
        name: null,
        material: null,
        print: null,
    };
    return view;
};

export default (props: FieldPropsType) => {
    return <ComponentConfig component="ProjectElement" as="fieldConfig">
        <Comp {...props} fieldConfig={undefined} />
    </ComponentConfig>
}

const Comp = (props: FieldPropsType&{fieldConfig: ComponentConfigType}) => {
    const form = props.form;
    const field = props.field;
    const fieldName = field.split(".");
    const currentField = fieldName.shift();
    const fieldSettings = props.config?.fields?.[currentField];
    const value = get(form.values, field) ?? [];
    const fieldReadOnly = useReadOnly(props);
    const actions = useMemo(() => ({
            update: ({request: {data}, props: {values}}) => {
                const newValue = [...(value ?? [])];
                const index = getRowIndex(values) ?? -1;
                if (index !== -1) {
                    newValue[index] = {...newValue[index], ...data};
                } else {
                    newValue.push(data);
                }
                form.setFieldValue(field, newValue);
            },
            create: ({request: {data}, props: {values}}) => {
                const newValue = [...(value ?? [])];
                const index = getRowIndex(values) ?? -1;
                if (index !== -1) {
                    newValue[index] = {...newValue[index], ...data};
                } else {
                    newValue.push(data);
                }
                form.setFieldValue(field, newValue);
            },
            remove: ({request: {data}, props: {values}}) => {
                const newValue = [...(value ?? [])];
                const index = getRowIndex(values) ?? -1;
                if (index !== -1) {
                    newValue.splice(index, 1);
                }
                form.setFieldValue(field, newValue);
            },
            findOne: ({props: {values: data}}) => {
                return data;
            }
        }), [form.values[props.config.idfield], JSON.stringify(value)]);
    return <>
        <Input.Wrapper
            label={<Translation base={[props.config.componentName + ".field." + currentField, props.config.componentName]} id={currentField} />}
            withAsterisk={fieldSettings.required}
            error={form.errors?.[field]}
        >
            {(value.map((element, idx) => {
                return <Grid key={idx}>
                    <Grid.Col xs={1}>
                        <Input.Wrapper
                            label={<>&nbsp;</>}
                        >
                            <Confirm
                                {...deleteProps}
                                onAccept={() => {
                                    actions.remove({request: {data: {...element}}, props: {values: {...element, [rowIndexField]: idx}}});
                                }}
                            >
                                <Button
                                    variant="subtle"
                                    fullWidth
                                >
                                    <IconTrash />
                                </Button>
                            </Confirm>
                        </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col xs={4}>
                        <DynamicComponent
                            config={props.fieldConfig}
                            session={props.session}
                            path="Editor/Fields/belongsTo/String"
                            field="name"
                            action="create"
                            readOnly={fieldReadOnly || props.readOnly}
                            objectValue={true}
                            value={undefined}
                            form={{
                                values: element,
                                errors: {},
                                setFieldValue: (f, v) => {
                                    const newElement = {...element};
                                    newElement[f] = v;
                                    actions.update({request: {data: {...newElement}}, props: {values: {...newElement, [rowIndexField]: idx}}});
                                }
                            }}
                        />
                    </Grid.Col>
                    <Grid.Col xs={3}>
                        <DynamicComponent
                            config={props.fieldConfig}
                            session={props.session}
                            path="Editor/Fields/belongsTo/Option"
                            field="material"
                            action="create"
                            readOnly={fieldReadOnly || props.readOnly}
                            objectValue={true}
                            value={undefined}
                            form={{
                                values: element,
                                errors: {},
                                setFieldValue: (f, v) => {
                                    const newElement = {...element};
                                    newElement[f] = v;
                                    actions.update({request: {data: {...newElement}}, props: {values: {...newElement, [rowIndexField]: idx}}});
                                }
                            }}
                        />
                    </Grid.Col>
                    <Grid.Col xs={3}>
                        <DynamicComponent
                            config={props.fieldConfig}
                            session={props.session}
                            path="Editor/Fields/belongsTo/Option"
                            field="print"
                            action="create"
                            readOnly={fieldReadOnly || props.readOnly}
                            objectValue={true}
                            value={undefined}
                            form={{
                                values: element,
                                errors: {},
                                setFieldValue: (f, v) => {
                                    const newElement = {...element};
                                    newElement[f] = v;
                                    actions.update({request: {data: {...newElement}}, props: {values: {...newElement, [rowIndexField]: idx}}});
                                }
                            }}
                        />
                    </Grid.Col>
                </Grid>
            }))}
            <Grid>
                <Grid.Col xs={12}>
                    <Button
                        variant="subtle"
                        fullWidth
                        onClick={() => {
                            actions.create({request: {data: {}}, props: {values: {}}});
                        }}
                    >
                        <IconPlus />
                    </Button>
                </Grid.Col>
            </Grid>
        </Input.Wrapper>
    </>;
};