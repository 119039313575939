import React, {useEffect, useState} from "react";
import {getRequest} from "../../utils/request/request";
import PropsProxy from "./PropsProxy";
import {ComponentConfigType} from "../../utils/component/ComponentConfig";

const configs = {};

const ComponentConfig = ({component, children, as, loader}: {component: string, children: any, as?: string, loader?: any}) => {
    const [config, setConfig] = useState(configs[component] ?? null);
    useEffect(() => {
        if (!component) return;
        setConfig(configs[component] ?? null);
        if (configs[component]) return;
        let req = getRequest("/components", {action: component + ".config"});
        req.then((config) => {
            configs[component] = config;
            setConfig(config);
        }).catch((e) => {
            console.error(e);
        });
        return () => {
            req.abort();
        }
    }, [component]);
    if (config) {
        return <PropsProxy {...{[as ?? "config"]: config}}>
            {children}
        </PropsProxy>
    } else if (loader) {
        return loader;
    }
    return null;
}

export default ComponentConfig;

type ComponentConfigPropsType = {
    config: ComponentConfigType
};

export const withComponentConfig = (component: string) => {
    return <P extends object>(Component: React.ComponentType<P>): React.ForwardRefExoticComponent<Omit<P, keyof ComponentConfigPropsType>> => {
        return React.forwardRef((props, ref) => {
            return <ComponentConfig component={component}>
                <Component {...props as P} />
            </ComponentConfig>;
        }) as React.ForwardRefExoticComponent<Omit<P, keyof ComponentConfigPropsType>>;
    };
}