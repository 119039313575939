import AbortablePromise from "./AbortablePromise";
import {AbortControllerType, AbortSignalType} from "./controller";

export default class PromiseRequest<T extends any = any> extends AbortablePromise<T> {

    protected thenCount = 0;
    protected catchCount = 0;

    constructor (executor: <TResult1 = T, TResult2 = never>(onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null, onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null, signal?: AbortSignalType) => void, abortController?: AbortControllerType) {
        super(executor, abortController);
        this.then = this.then.bind(this);
        this.catch = this.catch.bind(this);
        this.hasThen = this.hasThen.bind(this);
        this.hasCatch = this.hasCatch.bind(this);
    }

    then<TResult1 = T, TResult2 = never>(onfulfilled?: ((value: T) => (PromiseLike<TResult1> | TResult1)) | undefined | null, onrejected?: ((reason: any) => (PromiseLike<TResult2> | TResult2)) | undefined | null): Promise<TResult1 | TResult2> {
        if ("function" === typeof onfulfilled) {
            this.thenCount++;
        }
        if ("function" === typeof onrejected) {
            this.catchCount++;
        }
        return super.then(onfulfilled, onrejected);
    }

    catch<TResult = never>(onRejected?: ((reason: any) => (PromiseLike<TResult> | TResult)) | undefined | null): Promise<T | TResult> {
        if ("function" === typeof onRejected) {
            this.catchCount++;
        }
        return super.catch(onRejected);
    }

    hasThen(): boolean {
        return this.thenCount > 0;
    }

    hasCatch(): boolean {
        return this.catchCount > 0;
    }
}